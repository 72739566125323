import React from 'react';
import Form from '../FormStructure';

const Formation = ({ slug, datas, path, filter }) => {
  const collection = ['cfa', 'adress_cfa', 'school_info', 'formation_info', 'cost_info', 'rgpd_info'];

  return (
    <div>
      {collection.map((item) => (
        <Form key={item} newDatas={datas[item]} path={path} objectName={item} slug={slug} filter={filter} formEdit={true}></Form>
      ))}
    </div>
  );
};

export default Formation;
