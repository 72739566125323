import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {

  return (
    <CircularProgress />
  )
}

export default Loader;