import React from 'react';
import Button from '@material-ui/core/Button';
import Workbook from 'react-excel-workbook';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';

const Export = ({ datasToExport }) => {
  return (
    <div className="export-button download">
      <div className="row text-center">
        <Workbook
          filename="ad-datas.xlsx"
          element={
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              size="large"
            >
              <CloudDownloadRoundedIcon />
              Télécharger les données
            </Button>
          }
        >
          <Workbook.Sheet data={datasToExport} name="Datas">
            <Workbook.Column label="École" value="school" />
            <Workbook.Column label="Ville" value="city" />
            <Workbook.Column
              label="Intitulé Formation"
              value="exact_title_level"
            />
            <Workbook.Column label="Niveau Formation" value="grade" />
            <Workbook.Column label="Nom Étudiant" value="lastname" />
            <Workbook.Column label="Prenom Étudiant" value="firstname" />
            <Workbook.Column
              label="Date de naissance Étudiant"
              value="birthday"
            />
            <Workbook.Column
              label="Civilité Maître de stage 1"
              value="master_1_civil"
            />
            <Workbook.Column
              label="Nom Maître de stage 1"
              value="master_1_lastname"
            />
            <Workbook.Column
              label="Prénom Maître de stage 1"
              value="master_1_firstname"
            />
            <Workbook.Column
              label="Fonction Maître de stage 1"
              value="master_1_function"
            />
            <Workbook.Column
              label="Email Maître de stage 1"
              value="master_1_email"
            />
            <Workbook.Column
              label="Civilité Maître de stage 2"
              value="master_2_civil"
            />
            <Workbook.Column
              label="Nom Maître de stage 2"
              value="master_2_lastname"
            />
            <Workbook.Column
              label="Prénom Maître de stage 2"
              value="master_2_firstname"
            />
            <Workbook.Column
              label="Fonction Maître de stage 2"
              value="master_2_function"
            />
            <Workbook.Column
              label="Email Maître de stage 2"
              value="master_2_email"
            />
            <Workbook.Column label="Nom Entreprise" value="company_name" />
            <Workbook.Column
              label="Adresse de facturation"
              value="company_billing_number_street"
            />
            <Workbook.Column
              label="Code postal"
              value="company_billing_zip_code"
            />
            <Workbook.Column label="Ville" value="company_billing_city" />
          </Workbook.Sheet>
        </Workbook>
      </div>
    </div>
  );
};

export default Export;
