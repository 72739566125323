import React, { Component, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../Services/Loader";
import Schools from "./Inputs/Schools";
import Cities from "./Inputs/Cities";
import Grades from "./Inputs/Grades";
import Levels from "./Inputs/Levels";
import Student from "./Inputs/Student";
import firestoreFunctions from "../../../../../Services/firestoreFunctions";
import apprentice from "../../../../../Templates/apprentice.json";
import company from "../../../../../Templates/company.json";
import contract from "../../../../../Templates/contract.json";
import link from "../../../../../Templates/link.json";
import formation from "../../../../../Templates/formation.json";
import remuneration from "../../../../../Templates/renumeration.json";
// import progress from '../../../Templates/progress.json'
import Grid from "@material-ui/core/Grid";
import fsDatabase from "../../../../../Services/firestoreFunctions";

const CreateLink = (props) => {
  const [perms, setPerms] = useState(null);
  const [state, setState] = useState({
    isFetching: true,
    inputLock: 0,
    links: props.links,
    datas: props.datas,
    schools: [],
    school: "",
    cities: [],
    city: "",
    grades: [],
    grade: "",
    levels: [],
    level: "",
    lastname: "",
    firstname: "",
    status: 0,
    cerfa: {},
    apprentice: apprentice,
    company: company,
    contract: contract,
    link: link,
    remuneration: remuneration,
    formation: formation,
    disableButton: true,
  });

  useEffect(() => {
    if (
      state.school !== "" &&
      state.city !== "" &&
      state.grade !== "" &&
      state.level !== "" &&
      state.lastname.length > 0 &&
      state.firstname.length > 0
    ) {
      setState({ ...state, disableButton: false });
    } else {
      setState({ ...state, disableButton: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.school,
    state.city,
    state.grade,
    state.level,
    state.firstname,
    state.lastname,
  ]);

  useEffect(() => {
    const getPerms = async () => {
      const response = await fsDatabase.dbGet("perms", "perms");
      setPerms(response);
    };

    getPerms();
  }, []);

  useEffect(() => {
    const setSchools = () => {
      var array = state.datas.map((data) => data.school);
      var newArray = [...new Set(array)];

      let userPerms = [];

      props.user.school_perms.forEach((el, index) => {
        if (el !== 0) {
          userPerms.push(perms.school_perms[el]);
        }
      });

      let allowedSchools = [];

      if (props.user.school_perms[0] === 0) {
        allowedSchools = newArray.filter((el) =>
          perms.school_perms.includes(el)
        );
      } else {
        allowedSchools = newArray.filter((el) => userPerms.includes(el));
      }

      setState({ ...state, schools: allowedSchools, isFetching: false });
    };

    if (perms) setSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.datas, perms]);

  const setCities = (school) => {
    const dataFilter = state.datas.filter((data) => data.school === school);
    var array = dataFilter.map((data) => data.city);
    var newArray = [...new Set(array)];

    let userPerms = [];

    props.user.city_perms.forEach((el, index) => {
      if (el !== 0) {
        userPerms.push(perms.city_perms[el]);
      }
    });

    let allowedCities = [];

    if (props.user.city_perms[0] === 0) {
      allowedCities = newArray.filter((el) => perms.city_perms.includes(el));
    } else {
      allowedCities = newArray.filter((el) => userPerms.includes(el));
    }
    setState({ ...state, cities: allowedCities });
  };

  const setGrades = (city) => {
    const dataFilterSchool = state.datas.filter(
      (data) => data.school === state.school
    );
    const dataFilterCity = dataFilterSchool.filter(
      (data) => data.city === city
    );
    var array = dataFilterCity.map((data) => data.grade);
    var newArray = [...new Set(array)];
    setState({ ...state, grades: newArray });
    // setState({ ...state, cerfa: {} });
  };

  const setLevels = (grade) => {
    const dataFilterSchool = state.datas.filter(
      (data) => data.school === state.school
    );
    const dataFilterCity = dataFilterSchool.filter(
      (data) => data.city === state.city
    );
    const dataFilterGrade = dataFilterCity.filter(
      (data) => data.grade === grade
    );
    var array = dataFilterGrade.map((data) => data.level);
    var newArray = [...new Set(array)];
    setState({ ...state, levels: newArray });
    // setState({ ...state, cerfa: {} });
  };

  const getCerfaData = (level) => {
    const dataFilterSchool = state.datas.filter(
      (data) => data.school === state.school
    );
    const dataFilterCity = dataFilterSchool.filter(
      (data) => data.city === state.city
    );
    const dataFilterGrade = dataFilterCity.filter(
      (data) => data.grade === state.grade
    );
    const cerfaData = dataFilterGrade.filter((data) => data.level === level);
    setState({ ...state, cerfa: cerfaData });
  };

  const valueUpdate = (key, value) => {
    setState({ ...state, [key]: value });
    // setState({ ...state, inputLock: newInputLock });
  };

  const inputUpdate = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const createLink = () => {
    let randomIdCompany = Math.random().toString(36).substr(2, 30);
    let randomIdApprentice = Math.random().toString(36).substr(2, 30);
    let idsList = [];

    for (const element of state.links) {
      idsList.push(element.id_company);
    }
    const checkId = state.links.filter((link) => link === randomIdCompany);
    if (checkId.length === 0) {
      link.identity.apprentice_last_name.value = state.lastname;
      link.identity.apprentice_first_name.value = state.firstname;
      link.infos = {
        city: state.city,
        school: state.school,
        grade: state.grade,
        level: state.level,
        status: state.status,
        created_by: props.uid,
        id_company: randomIdCompany,
        id_apprentice: randomIdApprentice,
        form_edit: true,
        date: new Date().toLocaleString(),
      };

      for (const section in state.formation) {
        for (const [key, value] of Object.entries(state.cerfa[0])) {
          if (typeof state.formation[section][key] != "undefined") {
            let tempDatas = {
              ...state.formation,
              [section]: {
                ...state.formation[section],
                [key]: {
                  ...state.formation[section][key],
                  value: value,
                },
              },
            };
            state.formation = tempDatas;
          }
        }
      }
      saveToDb(randomIdCompany, link);
      // props.getLinks();
    } else {
      createLink();
    }
  };

  const saveToDb = async (randomIdCompany, link) => {
    let tempApprentice = {
      ...apprentice,
      general : {
        ...apprentice.general,
        apprentice_last_name : {
          ...apprentice.general.apprentice_last_name,
          value : state.lastname,
          is_valid : true,
          is_locked : true,
        },
        apprentice_first_name: {
          ...apprentice.general.apprentice_first_name,
          value : state.firstname,
          is_valid : true,
          is_locked : true,
        },
      }
    }
    await firestoreFunctions.dbWrite(
      "apprentices",
      randomIdCompany,
      tempApprentice
    );
    await firestoreFunctions.dbWrite("companies", randomIdCompany, company);
    await firestoreFunctions.dbWrite("contracts", randomIdCompany, contract);
    await firestoreFunctions.dbWrite(
      "remunerations",
      randomIdCompany,
      remuneration
    );
    await firestoreFunctions.dbWrite("links", randomIdCompany, link);
    await firestoreFunctions.dbWrite(
      "formations",
      randomIdCompany,
      state.formation
    );

    // TODO : Clean this
    // setTimeout(() => {
    //   document.location.reload();
      // props.getLinks();
  //   }, 2000);
  };

  return (
    <div className="manage-link">
      {state.isFetching ? (
        <Loader />
      ) : (
        <div className="add-container">
          <Grid container spacing={3}>
            <Schools
              schools={state.schools}
              onUpdate={valueUpdate}
              setState={setState}
              state={state}
              setNextComponent={setCities}
            ></Schools>
            <Cities
              cities={state.cities}
              onUpdate={valueUpdate}
              lock={state.school === "" ? true : false}
              setState={setState}
              state={state}
              setNextComponent={setGrades}
            ></Cities>
            <Grades
              grades={state.grades}
              onUpdate={valueUpdate}
              lock={state.city === "" ? true : false}
              setState={setState}
              state={state}
              setNextComponent={setLevels}
            ></Grades>
            <Levels
              levels={state.levels}
              // onUpdate={valueUpdate}
              lock={state.grade === "" ? true : false}
              getData={getCerfaData}
              setState={setState}
              state={state}
              // levels, state, setState, lock
            ></Levels>
          </Grid>
          <Student className="student" onUpdate={inputUpdate}></Student>
          <div className="ctn-btn">
            <Button
              className="send"
              size="large"
              disabled={state.disableButton}
              variant="contained"
              color="primary"
              onClick={() => {
                createLink();
                props.onClose();
              }}
            >
              Valider
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateLink;
