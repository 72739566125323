import firebase from '../firebase';

const db = firebase.firestore();

// Add datas to a document
const dbWrite = (collection, doc, datas) => {
  db.collection(collection).doc(doc).set(datas, { merge: true });
};

// Get datas from a document
const dbGet = async (collection, doc) => {
  try {
    const response = await db.collection(collection).doc(doc).get();

    if (response.exists) {
      return response.data();
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};

// Get id of all documents in collection
const dbGetAllId = async (collection) => {
  const datas = [];
  await db
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        datas.push(doc.id);
      });
    });
  return datas;
};

// Get live document
const dbLiveDoc = (collection, doc) => {
  db.collection(collection)
    .doc(doc)
    .onSnapshot((docSnapshot) => {
      if (docSnapshot.exist) {
        let updatedData = docSnapshot.data();
        return updatedData;
      } else {
        return false;
      }
    });
};

// Get live collection
const dbLiveCollection = (collection) => {
  const datas = [];
  db.collection(collection).onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      datas.push(doc.data());
    });
  });
  return datas;
};

// Delete doc
const dbDeleteDoc = (collection, doc) => {
  db.collection(collection)
    .doc(doc)
    .delete()
    .then(() => {})
    .catch((error) => {
      console.error('Error removing document: ', error);
    });
};

const fsDatabase = {
  dbWrite,
  dbGet,
  dbGetAllId,
  dbLiveDoc,
  dbLiveCollection,
  dbDeleteDoc,
};

export default fsDatabase;
