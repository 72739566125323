import React from 'react';
import fsDatabase from './firestoreFunctions';

const sectionAdd = (datas, collection, state, slug, path) => {
  let newDatas = {
    ...datas,
    [collection[state]]: {
      ...datas[collection[state]],
      section_settings: {
        ...datas[collection[state]].section_settings,
        is_active: true,
      },
    },
  };
  fsDatabase.dbWrite(path, slug, newDatas);
};

const sectionRemove = (datas, collection, state, slug, path, sectionClear) => {
  let nbSectionActive = state - 1;
  let newDatas = {
    ...datas,
    [collection[nbSectionActive]]: sectionClear[collection[nbSectionActive]],
  };
  fsDatabase.dbWrite(path, slug, newDatas);
};

const sectionDisplayOne = (datas, collection, state, slug, path, active) => {
  let newDatas = {
    ...datas,
    [collection]: {
      ...datas[collection],
      section_settings: {
        ...datas[collection].section_settings,
        is_active: active,
      },
    },
  };
  fsDatabase.dbWrite(path, slug, newDatas);
};

const sectionClearOne = (datas, collection, slug, path, sectionClear) => {
  let newDatas = {
    ...datas,
    [collection]: sectionClear[collection],
  };
  fsDatabase.dbWrite(path, slug, newDatas);
};

const manageFormSection = {
  sectionAdd,
  sectionRemove,
  sectionDisplayOne,
  sectionClearOne,
};

export default manageFormSection;
