import React, { useState } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import './home.scss'
import logo from '../../Assets/AD_Logo_White.svg'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const adTheme = createMuiTheme({
    palette: {
      secondary: {
        light: '#61D2AA',
        main: '#61D2AA',
        dark: '#00704F'
      },
    },
});




function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props}/>;
  }
  

function Home() {

    const db = firebase.firestore();

    const { handleSubmit, register } = useForm();
    const [open, setOpen] = useState(false);

    let checkSlug = ['infos.id_company', 'infos.id_apprentice']
    let i = 0

    const onSubmit = handleSubmit((data) => {
      let slug = data.link
      getLink('links', slug )
    });


    const getLink = (collection, slug) => {
        db.collection(collection).where(checkSlug[i], "==", slug)
        .get()
        .then((querySnapshot) => {
            if(querySnapshot.empty) {
              if (i >= 1) {
                setOpen(true)
              } else {
                i++
                getLink(collection, slug)
              }
            } else {
            querySnapshot.forEach((doc) => {
                routing(slug)
            });
          }
        })
        .catch((error) => {
          console.log(error)
        });
    }

    const copyClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };
    


    const history = useHistory();
  
  
    const routing = (slug) => {
      history.push("/form/" + slug);
    };


    return (
        <div className='home'>
          <ThemeProvider theme={adTheme}>
            <div className='ctn-form'>
                <img src={logo} />
                <h1 className='title'>Bienvenue sur l'application</h1>
                <h2 className='subtitle'>Veuillez renseigner le code qui vous à été fourni par l'tablissement d'enseignement</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField color="secondary"
                        {...register("link")}
                        name="link"
                        label="Votre code"
                        variant="outlined"
                    />
                    <Button variant="contained" color="secondary" type="submit">
                        Ouvrir mon formulaire
                    </Button>
                    <Snackbar open={open} autoHideDuration={2000} onClose={copyClose}>
                        <Alert onClose={copyClose} severity="error">
                            Aucun formulaire ouvert à la modification n'a été trouvé.
                        </Alert>
                    </Snackbar>
                </form>
            </div>
            </ThemeProvider>
        </div>
    )
}

export default Home;