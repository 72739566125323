import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';
import Loader from '../../Services/Loader';
import Form from './FormStructure';
import fsDatabase from '../../Services/firestoreFunctions';
import { ContactSupportOutlined } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import bddOpco from '../../Templates/bddOpco.json';

const AddSiret = ({ companies, path, slug }) => {
  const { enqueueSnackbar } = useSnackbar();
  let bddSiret = companies.general.company_siret;
  let [siret, setSiret] = useState('');
  let [fetchSiret, setFetchSiret] = useState(false);
  let [siretLoading, setSiretLoading] = useState(true);
  let [bddDatas, setDatas] = useState(companies);
  let [collections, setCollections] = useState();

  // 
  useEffect(() => {
    updateDatas()
  }, [companies]);

  const updateDatas = () => {
    let tempDatas = {}
    for (const [collection] of Object.entries(bddDatas)) {
      for (const [key, value] of Object.entries(bddDatas[collection])) {
        tempDatas = {
          ...tempDatas,
          [collection]: {
            ...tempDatas[collection],
            [key]: {
              ...companies[collection][key],
              value: companies[collection][key].value,
            },
          },
        };
      }
    }
    setDatas(tempDatas)
  }

  const checkSiret = async () => {
    if (siret.length == 14) {
      let siren = siret.slice(0, -5);
      const dataGouv = await fetchSiretApis(`https://entreprise.data.gouv.fr/api/sirene/v1/siret/${siret}`);
      const idcc = await fetchSiretApis(`https://siret2idcc.fabrique.social.gouv.fr/api/v2/${siret}`);
      const lastIdcc = idcc[0].conventions.length - 1;
      let apiDatas = {};
      let tempDatas = {};
      let tempCollections = [];
      if (!dataGouv) {
        enqueueSnackbar('Votre numéro de SIRET ne correspond à aucune entreprise', {
          variant: 'error',
        });
      } else {
        setFetchSiret(true);
        apiDatas = {
          general: {
            company_siret: siret,
            company_name: dataGouv.etablissement.nom_raison_sociale,
            company_complement: dataGouv.etablissement.complement_adresse,
            company_naf_code: dataGouv.etablissement.activite_principale,
          },
          company_adress: {
            company_zip_code: dataGouv.etablissement.code_postal,
            company_number_street: dataGouv.etablissement.geo_l4,
            company_city: dataGouv.etablissement.libelle_commune,
          },
        };
        if (idcc[0].conventions[lastIdcc]) {
          let idccNum = idcc[0].conventions[lastIdcc].num;
          apiDatas = {
            ...apiDatas,
            general: {
              ...apiDatas.general,
              company_idcc_code: idccNum,
              company_convention_name: idcc[0].conventions[lastIdcc].shortTitle,
              company_opco_name: bddOpco[idccNum].opco_name,
            },
          };
        }
        for (const [collection] of Object.entries(apiDatas)) {
          tempCollections.push(collection);
          for (const [key, value] of Object.entries(apiDatas[collection])) {
            if (value && key != 'company_siret') {
              tempDatas = {
                ...tempDatas,
                [collection]: {
                  ...tempDatas[collection],
                  [key]: {
                    ...bddDatas[collection][key],
                    value: value,
                    is_valid: true,
                  },
                },
              };
            }
          }
        }
        setDatas(tempDatas);
        setCollections(tempCollections);
        let fsDatas = {
          ...companies,
          ...tempDatas,
        };
        fsDatabase.dbWrite(path, slug, fsDatas);
        setTimeout(() => {
          setSiretLoading(false);
        }, 700);
      }
    } else {
      enqueueSnackbar('Votre numéro de SIRET doit comporter 14 chiffres', {
        variant: 'error',
      });
    }
  };

  const passSiret = () => {
    let newDatas = {
      ...companies,
      general: {
        ...companies.general,
        company_siret: {
          ...companies.general.company_siret,
          value: 'À COMPLÉTER',
        },
      },
    };
    let fsDatas = {
      ...companies,
      ...newDatas,
    };
    fsDatabase.dbWrite(path, slug, fsDatas);
  };

  const fetchSiretApis = async (url) => {
    try {
      const response = await fetch(url);
      const json = await response.json();
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return json;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSiretChange = (event) => {
    setFetchSiret(false);
    setSiretLoading(true);
    setDatas(companies);
    // setFetchSiret(false);
    const value = event.target.value;
    setSiret(value);
  };

  const validateSiret = () => {
    let newDatas = {
      ...companies,
      general: {
        ...companies.general,
        company_siret: {
          ...companies.general.company_siret,
          value: siret,
          is_valid: true,
        },
      },
    };
    let fsDatas = {
      ...companies,
      ...newDatas,
    };
    fsDatabase.dbWrite(path, slug, fsDatas);
  };

  return (
    <div class='ctn-add-siret'>
      <Paper>
        <div class='cte-add-siret'>
          <h2 className='check-info-title first-title'>Rentrez votre siret</h2>
          <p className='check-info-subtitle'>
            Vous pouvez rentrer maintenant votre SIRET pour pré-remplir une partie des informations requises et gagner du temps
          </p>
          <NumberFormat
            customInput={TextField}
            inputProps={{ maxLength: 14 }}
            key={'bddSiret'}
            placeholder={bddSiret.placeholder}
            label={bddSiret.label}
            autoComplete='no'
            value={siret}
            variant='outlined'
            fullWidth={true}
            onChange={handleSiretChange}
          />
          {!fetchSiret && (
            <div className='ctn-button'>
              <Button size='large' color='primary' className='button button-pass' onClick={passSiret}>
                Pré-remplir plus tard
              </Button>
              <Button size='large' variant='contained' color='primary' className='button button-add' onClick={checkSiret}>
                Valider
              </Button>
            </div>
          )}
          {fetchSiret && (
            <div>
              {siretLoading ? (
                <Loader />
              ) : (
                <div className='ctn-check-info'>
                  <h2 className='check-info-title'>Vérifiez vos informations</h2>
                  {collections.map((item) => (
                    <Form key={item} newDatas={bddDatas[item]} path={path} objectName={item} slug={slug} singleInput={true} formEdit={true}></Form>
                  ))}
                  <Button className='button-validate' size='large' variant='contained' color='secondary' onClick={validateSiret}>
                    les informations sont correctes
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};
export default AddSiret;
