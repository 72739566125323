import React, { Component, useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

const Schools = ({ schools, state, setState, setNextComponent }) => {
  const [school, setSchool] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setNextComponent(value);
    setSchool(value);
  };

  useEffect(() => {
    setState({ ...state, school: school });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school]);

  return (
    <Grid item md={3} sm={6} xs={12}>
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel id="demo-simple-select-outlined-label">École</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={school}
          onChange={handleChange}
          label="École"
        >
          {schools.map((index) => (
            <MenuItem key={index} value={index}>
              {index}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default Schools;
