import React, { useState, useEffect, useContext } from 'react';
import firebase from '../../../../../firebase';
import Loader from '../../../../../Services/Loader';
import CreateLink from './CreateLink';
import DisplayLinks from './DisplayLinks';
import Grid from '@material-ui/core/Grid';
import bddFormation from '../../../../../Templates/bddFormation.json';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
// import fsDatabase from './../../../../Services/firestoreFunctions';
import xlsColumns from '../../../../../Templates/xls-columns.json';
import Export from './Export';

import './manageContracts.scss';

// VARIABLES
const db = firebase.firestore();

// let bdd = {
//   cerfa: bddFormation,
//   links: {},
// };

// FUNCTIONS

const ManageContracts = ({ user, perms, uid }) => {
  const [fetch, setFetch] = useState({
    links: false,
  });
  const [open, setOpen] = useState(false);
  const [remunerations, setRemunerations] = useState();
  const [users, setUsers] = useState();
  const [bdd, setBdd] = useState({
    cerfa: bddFormation,
    links: {},
    student: {},
  });
  const [datasToExport, setDatasToExport] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [exportingStatus, setExportingStatus] = useState(0);

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = (event, reason) => {
    setOpen(false);
  };

  useEffect(() => {
    getLinks();
    getRemunerations();
    getUsers();
    // eslint-disable-next-line
  }, []);

  const exportDatas = () => {
    setExporting(true);
    getDatasToExport();
  };

  const getLinks = () => {
    let linkRef = db.collection('links');

    // Filtering by perms
    let cp = 'city_perms';
    let sp = 'school_perms';
    let citiesFilter = [];
    let schoolsFilter = [];
    if (user[cp][0] != 0) {
      for (const [, value] of user[cp].entries()) {
        citiesFilter.push(perms[cp][value]);
      }
      for (const [, value] of user[sp].entries()) {
        schoolsFilter.push(perms[sp][value]);
      }
      if (user[sp][0] !== 0) {
        linkRef = linkRef.where('infos.school', 'in', schoolsFilter);
      }
      // Can't do this : You cannot use more than one 'in' filter
      // .where("infos.city", "in", citiesFilter);
    }

    linkRef.onSnapshot((querySnapshot) => {
      let filteredDatas = [];
      let datas = [];
      querySnapshot.forEach((doc) => {
        datas.push(doc.data());
      });

      if (user[cp][0] !== 0)
        datas = datas.filter((el) => citiesFilter.includes(el.infos.city));

      if (user[sp][0] !== 0) {
        for (const [, value] of user[sp].entries()) {
          filteredDatas = [
            ...filteredDatas,
            ...datas.filter((data) => data.infos.school === perms[sp][value]),
          ];
        }
        bdd.links = filteredDatas;
      } else {
        bdd.links = datas;
      }

      setFetch({
        ...fetch,
        links: true,
      });
    });
  };

  const getDatasToExport = async () => {
    let tempDatasToExport = {};
    const formationsDatas = await firebase
      .firestore()
      .collection('formations')
      .get();
    formationsDatas.docs.map((doc) => {
      const datas = doc.data();
      tempDatasToExport = {
        ...tempDatasToExport,
        [doc.id]: {
          ...tempDatasToExport[doc.id],
          exact_title_level: datas.formation_info.exact_title_level.value,
          grade: datas.formation_info.grade.value,
        },
      };
    });
    setExportingStatus(1);
    const apprenticesDatas = await firebase
      .firestore()
      .collection('apprentices')
      .get();
    apprenticesDatas.docs.map((doc) => {
      const datas = doc.data();
      tempDatasToExport = {
        ...tempDatasToExport,
        [doc.id]: {
          ...tempDatasToExport[doc.id],
          lastname: datas.general.apprentice_last_name.value,
          firstname: datas.general.apprentice_first_name.value,
          birthday: datas.general.apprentice_birth_date.value,
        },
      };
    });
    setExportingStatus(2);
    const contractsDatas = await firebase
      .firestore()
      .collection('contracts')
      .get();
    contractsDatas.docs.map((doc) => {
      const datas = doc.data();
      tempDatasToExport = {
        ...tempDatasToExport,
        [doc.id]: {
          ...tempDatasToExport[doc.id],
          master_1_civil:
            datas.apprentice_master_1.apprentice_master_civil.value,
          master_1_lastname:
            datas.apprentice_master_1.apprentice_master_last_name.value,
          master_1_firstname:
            datas.apprentice_master_1.apprentice_master_first_name.value,
          master_1_function:
            datas.apprentice_master_1.apprentice_master_function.value,
          master_1_email:
            datas.apprentice_master_1.apprentice_master_mail.value,
          master_2_civil:
            datas.apprentice_master_2.apprentice_master_2_civil.value,
          master_2_lastname:
            datas.apprentice_master_2.apprentice_master_2_last_name.value,
          master_2_firstname:
            datas.apprentice_master_2.apprentice_master_2_first_name.value,
          master_2_function:
            datas.apprentice_master_2.apprentice_master_2_function.value,
          master_2_email:
            datas.apprentice_master_2.apprentice_master_2_mail.value,
        },
      };
    });
    setExportingStatus(3);
    const companiesDatas = await firebase
      .firestore()
      .collection('companies')
      .get();
    companiesDatas.docs.map((doc) => {
      const datas = doc.data();
      tempDatasToExport = {
        ...tempDatasToExport,
        [doc.id]: {
          ...tempDatasToExport[doc.id],
          company_name: datas.general.company_name.value,
          company_billing_number_street:
            datas.company_billing_adress.company_billing_number_street.value,
          company_billing_zip_code:
            datas.company_billing_adress.company_billing_zip_code.value,
          company_billing_city:
            datas.company_billing_adress.company_billing_city.value,
        },
      };
    });
    setExportingStatus(4);
    const linksDatas = await firebase.firestore().collection('links').get();
    linksDatas.docs.map((doc) => {
      const datas = doc.data();
      tempDatasToExport = {
        ...tempDatasToExport,
        [doc.id]: {
          ...tempDatasToExport[doc.id],
          school: datas.infos.school,
          city: datas.infos.city,
        },
      };
    });
    setExportingStatus(5);
    const readyDatasToExport = [];
    for (const [, value] of Object.entries(tempDatasToExport)) {
      readyDatasToExport.push(value);
    }
    setDatasToExport(readyDatasToExport);
  };

  const getRemunerations = async () => {
    let tempDatas = {};
    await db
      .collection('remunerations')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          tempDatas = {
            ...tempDatas,
            [doc.id]: {
              startContract:
                doc.data().contract_date_duration.contract_start_date.value,
            },
          };
        });
      });
    await setRemunerations(tempDatas);
  };

  const getUsers = async () => {
    let tempDatas = {};
    await db
      .collection('users')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          tempDatas = {
            ...tempDatas,
            [doc.id]: {
              firstname: doc.data().firstname,
              lastname: doc.data().lastname,
            },
          };
        });
      });
    await setUsers(tempDatas);
  };

  // Exports;

  // let datasColumns = xlsColumns;
  // let datasToExport = [datasColumns];
  // let columnsDatas = [];
  // const dbf = [apprentices, companies, contracts, formations, remunerations];
  // let inputCount = 0;

  // for (const property in datasColumns) {
  //   columnsDatas.push(
  //     <Workbook.Column key={property} label={property} value={property} />,
  //   );
  // }

  // dbf.map((dbf) => {
  //   for (const [key] of Object.entries(dbf)) {
  //     let section = key;
  //     for (var input in dbf[section]) {
  //       let value = dbf[section][input].value;
  //       if (
  //         input != 'section_settings' &&
  //         value != undefined &&
  //         input in datasColumns
  //       ) {
  //         inputCount++;
  //         let tempDatas = {
  //           ...datasColumns,
  //           [input]: value,
  //         };
  //         datasColumns = tempDatas;
  //         datasToExport = [datasColumns];
  //       }
  //     }
  //   }
  // });

  return (
    <div className="manage-contracts">
      <div className="sct-header">
        <h1 className="header-title">Dashboard Admin</h1>
        {/* <h2 className='header-subtitle'></h2> */}
      </div>
      <div className="sct-content">
        {Object.values(fetch).every((item) => item) &&
        remunerations &&
        users ? (
          <div className="ctn-contracts">
            <div className="actions-buttons">
              <Button
                variant="contained"
                id="btn-create-contract"
                color="primary"
                onClick={modalOpen}
                className="create-form"
              >
                CRÉER UN FORMULAIRE
              </Button>
              {exporting ? (
                user.main_perm === 0 && (
                  <div>
                    {datasToExport.length > 0 ? (
                      <Export datasToExport={datasToExport}></Export>
                    ) : (
                      <div class="export-loading">
                        <Loader />
                        <p class="export-loading-text">
                          Assemblage des données <b>{exportingStatus} / 5</b>
                        </p>
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div className="export-button">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    size="large"
                    onClick={exportDatas}
                  >
                    Exporter les données
                  </Button>
                </div>
              )}
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={modalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              className="modal-create-link"
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className="ctn-create-link">
                  <Grid className="cpn-add-link" item xs={12}>
                    <h1>Création d'une fiche</h1>
                    <CreateLink
                      datas={bdd.cerfa}
                      links={bdd.links}
                      user={user}
                      onClose={modalClose}
                      getLinks={getLinks}
                      uid={uid}
                    />
                  </Grid>
                </div>
              </Fade>
            </Modal>
            <Grid className="cpn-links" item xs={12}>
              <DisplayLinks
                links={bdd.links}
                loadFilter={open}
                mainPerm={user.main_perm}
                remunerations={remunerations}
                users={users}
              />
            </Grid>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ManageContracts;
