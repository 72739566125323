import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow } from 'material-table';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../../Services/Loader';
import firestoreFunctions from '../../../../../Services/firestoreFunctions';
import firebase from '../../../../../firebase';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import CreateIcon from '@material-ui/icons/Create';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useSnackbar } from 'notistack';

import './displaylinks.scss';
import { Tooltip } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import fsDatabase from '../../../../../Services/firestoreFunctions';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

let nameFilters = {};
let establishmentFilters = {};
let classFilters = {};
let statusFilters = {};
let selectedRowsIds = [];
let downloadLoading = false;

const DisplayLinks = (props) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [rows, setRows] = useState([]);
  const [activeFilterClass, setActiveFilter] = useState('inactive');
  const [filtersFetching, fetchFilters] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorCopyEl, setAnchorCopyEl] = useState(null);
  const [selectedRowId, setRowId] = useState('');
  const [selectedRowIdApprentice, setRowIdApprentice] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [openLink, setOpenLink] = useState(false);
  const [currentDlUrl, setCurrentDlUrl] = useState('');
  const [rowId, setSelectedRow] = useState(null);
  const statusList = [
    'À envoyer',
    'Envoyé',
    'À vérifier',
    'Exporté',
    'Signé',
    'À définir',
  ];

  const handleLinkClose = () => {
    setOpenLink(false);
  };

  useEffect(() => {
    setFilters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    openRow();
    // eslint-disable-next-line
  }, [rowId]);

  const openRow = () => {
    rowId && window.open('/form/' + rowId);
  };

  // const copyClick = (event) => {
  //   enqueueSnackbar(`L'url du formulaire ${event.currentTarget.name} à bien été copié : ${event.currentTarget.id}`, {
  //     variant: 'success',
  //   });
  // };

  const handleSelection = (rows) => {
    let tempSelectedIds = [];
    rows.forEach((e) => {
      tempSelectedIds.push(e.id);
    });
    selectedRowsIds = tempSelectedIds;
  };

  const deleteClick = () => {
    setOpenDelete(true);
  };

  const deleteClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDelete(false);
  };

  const getPath = (links) =>
    `documents/${links.infos.school}/${links.infos.city}/${links.identity.apprentice_last_name.value}_${links.identity.apprentice_first_name.value}_${links.infos.id_company}`;

  const deleteRows = async () => {
    const collectionToDelete = [
      'links',
      'apprentices',
      'companies',
      'contracts',
      'formations',
      'progress',
      'remunerations',
    ];
    selectedRowsIds.forEach(async (id) => {
      // Construct path before deletion
      const links = await firestoreFunctions.dbGet('links', id);
      const folderName = getPath(links);
      // Delete corresponding folder
      const deleteFolder = firebase.functions().httpsCallable('deleteFolder');
      deleteFolder({ folderName })
        .then((result) => {
          // Read result of the Cloud Function
          console.log('result', result);
        })
        .catch((err) => console.log('error deleting :', err));

      collectionToDelete.forEach((collection) => {
        firestoreFunctions.dbDeleteDoc(collection, id);
      });
    });

    setOpenDelete(false);
  };

  const downloadHandleClick = (event) => {
    event.stopPropagation();
    setRowId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const copyHandleClick = (event) => {
    const splitId = event.currentTarget.id.split(' ');
    event.stopPropagation();
    setRowId(splitId[0]);
    setRowIdApprentice(splitId[1]);
    setAnchorCopyEl(event.currentTarget);
  };

  const handleLocalCopy = (id) => {
    id == 'company'
      ? enqueueSnackbar(
          `L'url du formulaire entreprise à bien été copié : ${selectedRowId}`,
          {
            variant: 'success',
          },
        )
      : enqueueSnackbar(
          `L'url du formulaire étudiant à bien été copié : ${selectedRowIdApprentice}`,
          {
            variant: 'success',
          },
        );
    setAnchorCopyEl(null);
  };

  const getFileName = (links, fileType) =>
    `${fileType === 'cerfa' ? 'Cerfa' : 'Conv'}App_${links.infos.school}_${
      links.infos.city
    }_${links.infos.grade}_${links.infos.level}_${
      links.identity.apprentice_last_name.value
    }_${links.identity.apprentice_first_name.value}_${links.infos.id_company}.${
      fileType === 'conv-pdf' || fileType === 'cerfa' ? 'pdf' : 'docx'
    }`;

  const handleLocalDownload = async (fileType) => {
    const links = await firestoreFunctions.dbGet('links', selectedRowId);
    const path = getPath(links);
    // "CerfaApp_ESP_Bordeaux_M1M2_CORPO_Jeremy_Di Meglio_7ybcu4syjg9.pdf"
    const fileName = getFileName(links, fileType);
    const storage = firebase.storage();
    const pathRef = storage.ref(`${path}/${fileName}`);
    pathRef
      .getDownloadURL()
      .then((url) => {
        setCurrentDlUrl(url);
        setOpenLink(true);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
            enqueueSnackbar("Le document n'existe pas.", {
              variant: 'warning',
            });
            break;
          case 'storage/unauthorized':
            enqueueSnackbar(
              "Vous n'avez pas la permission de télécharger ce document.",
              {
                variant: 'warning',
              },
            );
            break;
          default:
            enqueueSnackbar(
              'Une erreur est survenue lors de la récupération du lien de téléchargement.',
              {
                variant: 'error',
              },
            );
        }
      });
  };

  const downloadHandleClose = (file) => {
    downloadLoading = true;
    if (typeof file === 'string') {
      fetch(`https://www.cerfa-api.fr/${file}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ id: selectedRowId }),
      })
        .then((res) => res.json())
        .then((res) => {
          downloadLoading = false;
          setRowId('');
          if (res.success) {
            enqueueSnackbar('Nouveau document généré avec succès', {
              variant: 'success',
            });
            let typeFile = file === 'generate-cerfa' ? 'cerfa' : 'conv-word';
            handleLocalDownload(typeFile);
          } else {
            enqueueSnackbar('Erreur : ' + res.message, {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          downloadLoading = false;
          setRowId('');
          enqueueSnackbar(
            "Un problème de communication entre l'application et le serveur est survenu",
            {
              variant: 'error',
            },
          );
        });
    }

    setAnchorEl(null);
  };

  const downloadResponse = (res) => {
    enqueueSnackbar(res, {
      variant: 'success',
    });
  };

  const setFilters = () => {
    props.links.forEach((e) => {
      let i = e.infos;

      let newName = {
        ...nameFilters,
        [[
          e.identity.apprentice_last_name.value,
          e.identity.apprentice_first_name.value,
        ]]: `${e.identity.apprentice_first_name.value} ${e.identity.apprentice_last_name.value}`,
      };
      nameFilters = newName;

      let newEstablishment = {
        ...establishmentFilters,
        [[i.school, i.city]]: `${i.school} ${i.city}`,
      };
      establishmentFilters = newEstablishment;

      let newClass = {
        ...classFilters,
        [[i.grade, i.level]]: `${i.grade} ${i.level}`,
      };
      classFilters = newClass;
      let statusNumber =
        i.status || i.status === 0 ? i.status : statusList.length - 1;
      let newStatus = {
        ...statusFilters,
        [statusNumber]: `${statusList[statusNumber]}`,
      };
      statusFilters = newStatus;
    });
    fetchFilters(false);
  };

  const activeFilter = () => {
    activeFilterClass == 'inactive'
      ? setActiveFilter('')
      : setActiveFilter('inactive');
  };

  const isProd = process.env.REACT_APP_ENV === 'production' ? true : false;

  const changeStatus = (event) => {
    let value = event.target.value;
    let slug = event.target.name;
    event.stopPropagation();
    let fsDatas = {
      infos: {
        status: value,
      },
    };
    console.log(fsDatas);
    fsDatabase.dbWrite('links', slug, fsDatas);
  };

  const closeStatus = (event) => {
    event.stopPropagation();
  };

  // let rows = [];
  useEffect(() => {
    let tempRows = [];
    props.links.forEach((e) => {
      let i = e.infos;
      tempRows.push({
        id: i.id_company,
        firstname: e.identity.apprentice_first_name.value,
        lastname: e.identity.apprentice_last_name.value,
        name: [
          e.identity.apprentice_last_name.value,
          e.identity.apprentice_first_name.value,
        ],
        school: i.school,
        city: i.city,
        establishment: [i.school, i.city],
        grade: i.grade,
        level: i.level,
        class: [i.grade, i.level],
        state: i.form_edit,
        startcontract: props.remunerations[i.id_company]
          ? props.remunerations[i.id_company].startContract
          : '',
        date: i.date,
        uid: i.created_by ? i.created_by : null,
        // linkCompany: [
        //   <div>
        //     <CopyToClipboard key='copy_company' text={'https://esd-digital-cerfa.web.app/form/' + i.id_company}>
        //       <IconButton id={i.id_company} name='entreprise' onClick={copyClick}>
        //         <WorkRoundedIcon />
        //       </IconButton>
        //     </CopyToClipboard>
        //     <CopyToClipboard key='copy_apprentice' text={'https://esd-digital-cerfa.web.app/form/' + i.id_apprentice}>
        //       <IconButton id={i.id_apprentice} name='apprenti' onClick={copyClick}>
        //         <SchoolRoundedIcon />
        //       </IconButton>
        //     </CopyToClipboard>
        //   </div>,
        // ],
        status: i.status || i.status === 0 ? i.status : statusList.length - 1,
        download: [
          <>
            <Tooltip title="Copier">
              <IconButton
                id={`${i.id_company} ${i.id_apprentice}`}
                className="btn-download"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={copyHandleClick}
              >
                <FileCopyRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Télécharger un document">
              <IconButton
                id={i.id_company}
                className="btn-download"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={downloadHandleClick}
              >
                <CloudDownloadRoundedIcon />
              </IconButton>
            </Tooltip>
          </>,
        ],
      });
    });

    setRows(tempRows);
  }, [props.links]);

  return (
    <div className="display-links">
      {downloadLoading && <Loader />}
      {filtersFetching ? (
        <Loader />
      ) : (
        <div className="material-links">
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: 'Date',
                field: 'date',
                defaultSort: 'desc',
                hidden: true,
              },
              {
                title: 'Étudiant',
                field: 'name',
                filtering: false,
                render: (rowData) => (
                  <Tooltip
                    title={
                      rowData.uid != null
                        ? `Gestionnaire : ${
                            props.users[rowData.uid].firstname
                          } ${props.users[rowData.uid].lastname}`
                        : 'Gestionnaire inconnu'
                    }
                    aria-label="aide"
                  >
                    <div>
                      <p className="info-main">{rowData.lastname}</p>
                      <p className="info-secondary">{rowData.firstname}</p>
                    </div>
                  </Tooltip>
                ),
                lookup: nameFilters,
              },
              {
                title: 'Campus',
                field: 'establishment',
                render: (rowData) => (
                  <div>
                    <p className="info-main">{rowData.school}</p>
                    <p className="info-secondary">{rowData.city}</p>
                  </div>
                ),
                lookup: establishmentFilters,
              },
              {
                title: 'Classe',
                field: 'class',
                render: (rowData) => (
                  <div>
                    <p className="info-main">{rowData.grade}</p>
                    <p className="info-secondary">{rowData.level}</p>
                  </div>
                ),
                lookup: classFilters,
              },
              {
                title: 'Début du contrat',
                field: 'startcontract',
                filtering: false,
                render: (rowData) => (
                  <div>
                    <p className="info-secondary">{rowData.startcontract}</p>
                  </div>
                ),
              },
              {
                title: 'État',
                field: 'state',
                filtering: false,
                render: (rowData) => (
                  <Tooltip
                    title={
                      rowData.state
                        ? 'Le formulaire est en cours de modification'
                        : "Le formulaire a été validé par l'entreprise"
                    }
                    aria-label="aide"
                  >
                    {/* Ajouter remunerations.contract_date_duration.contract_start_date.value dans le tableau */}
                    <div className={'info-state state-' + rowData.state}></div>
                  </Tooltip>
                ),
              },
              {
                title: 'Statut',
                field: 'status',
                defaultSort: 'desc',
                render: (rowData) => (
                  <FormControl
                    title={statusList[rowData.status]}
                    className="ctn-status"
                    variant="outlined"
                    value={rowData.status}
                  >
                    <Select
                      className={'status-select status-' + rowData.status}
                      name={rowData.id}
                      value={rowData.status}
                      onChange={changeStatus}
                      onClose={closeStatus}
                    >
                      <MenuItem className={'to-send status-0'} value={0}>
                        {statusList[0]}
                      </MenuItem>
                      <MenuItem className={'send status-1'} value={1}>
                        {statusList[1]}
                      </MenuItem>
                      <MenuItem className={'to-check status-2'} value={2}>
                        {statusList[2]}
                      </MenuItem>
                      <MenuItem className={'exported status-3'} value={3}>
                        {statusList[3]}
                      </MenuItem>
                      <MenuItem className={'signed status-4'} value={4}>
                        {statusList[4]}
                      </MenuItem>
                      <MenuItem className={'signed status-5'} value={5}>
                        {statusList[5]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                ),
                lookup: statusFilters,
              },
              {
                title: 'Actions',
                field: 'download',
                filtering: false,
              },
            ]}
            data={rows}
            onRowClick={(evt, selectedRow) => {
              window.open(`/form/${selectedRow.id}`, '_blank');
              evt.stopPropagation();
            }}
            title="Liste des formulaires"
            options={{
              pageSize: 20,
              filtering: true,
              selection: true,
            }}
            actions={[
              {
                tooltip: 'Supprimer',
                icon: DeleteOutline,
                onClick: (evt, data) => deleteClick(),
              },
              {
                icon: FilterList,
                tooltip: 'Filtres',
                isFreeAction: true,
                onClick: activeFilter,
              },
            ]}
            components={{
              FilterRow: (props) => (
                <div className={'filter-row ' + activeFilterClass}>
                  <MTableFilterRow {...props} />
                </div>
              ),
            }}
            onSelectionChange={(rows) => handleSelection(rows)}
          />
          <Dialog
            open={openDelete}
            id="delete-modal"
            onClose={deleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <WarningRoundedIcon />
              <h2 className="modal-title">
                {props.mainPerm == 0
                  ? 'Voulez vous supprimer les formulaires ?'
                  : "Vous n'avez pas l'autorisation de supprimer les fomulaires"}
              </h2>
            </DialogTitle>
            <DialogContent>
              {props.mainPerm == 0 ? (
                <DialogContentText id="alert-dialog-description">
                  Attention cette action est irréversible et entrainera la
                  suppression de l'ensemble des formulaires sélectionnés
                </DialogContentText>
              ) : (
                <DialogContentText id="alert-dialog-description">
                  Veuillez vous rapprocher d'un administrateur pour supprimer
                  des données.
                </DialogContentText>
              )}
            </DialogContent>
            {props.mainPerm == 0 ? (
              <DialogActions>
                <Button onClick={deleteClose} color="primary">
                  Annuler
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={deleteRows}
                  autoFocus
                >
                  Supprimer
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button onClick={deleteClose} color="primary">
                  Revenir en arrière
                </Button>
              </DialogActions>
            )}
          </Dialog>

          {/* Dialog to display download link */}
          <Dialog
            open={openLink}
            onClose={handleLinkClose}
            aria-labelledby="dialog-download-link-title"
            aria-describedby="dialog-download-link-content"
          >
            <DialogTitle id="dialog-download-link-title">
              Lien de téléchargement du fichier demandé
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="dialog-download-link-content">
                <a href={currentDlUrl} download>
                  Cliquez ici pour télécharger le fichier
                </a>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLinkClose} color="primary" autoFocus>
                Fermer
              </Button>
            </DialogActions>
          </Dialog>

          {/* Generate menu */}
          <Menu
            id="download-actions"
            className="shadow"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={downloadHandleClose}
          >
            <MenuItem
              className="cerfa"
              onClick={() => downloadHandleClose('generate-cerfa')}
            >
              <ListItemIcon className="cerfa-icon">
                <GetAppRoundedIcon fontSize="small" />
              </ListItemIcon>
              CERFA
            </MenuItem>
            <MenuItem
              className="convention"
              onClick={() => downloadHandleClose('generate-convention')}
            >
              <ListItemIcon className="convention-icon">
                <GetAppRoundedIcon fontSize="small" />
              </ListItemIcon>
              Convention
            </MenuItem>
            {/* <MenuItem className='synthesis' onClick={() => downloadHandleClose('Synthèse')}>
              <ListItemIcon className='synthesis-icon'>
                <GetAppRoundedIcon fontSize='small' />
              </ListItemIcon>
              Synthèse
            </MenuItem>
            <MenuItem className='xls' onClick={() => downloadHandleClose('XLS')}>
              <ListItemIcon className='cls-icon'>
                <GetAppRoundedIcon fontSize='small' />
              </ListItemIcon>
              XLS
            </MenuItem> */}
          </Menu>

          {/* Copy menu */}
          <Menu
            anchorEl={anchorCopyEl}
            keepMounted
            open={Boolean(anchorCopyEl)}
            onClose={() => setAnchorCopyEl(null)}
          >
            <CopyToClipboard
              key="copy_company"
              text={
                isProd
                  ? `https://apprentissage.ad-education.com/form/${selectedRowId}`
                  : `https://esd-digital-cerfa-dev.web.app/form/${selectedRowId}`
              }
            >
              <MenuItem onClick={() => handleLocalCopy('company')}>
                Lien entreprise
              </MenuItem>
            </CopyToClipboard>
            <CopyToClipboard
              key="copy_company"
              text={
                isProd
                  ? `https://apprentissage.ad-education.com/form/${selectedRowIdApprentice}`
                  : `https://esd-digital-cerfa-dev.web.app/form/${selectedRowIdApprentice}`
              }
            >
              <MenuItem onClick={() => handleLocalCopy('apprentice')}>
                Lien étudiant
              </MenuItem>
            </CopyToClipboard>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default DisplayLinks;
