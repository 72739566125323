import React, { useState, useEffect, useContext } from 'react';
import firebase from '../../firebase';
import Loader from '../../Services/Loader';
import ManageForms from './ManageForms';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import './form.scss';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const adTheme = createMuiTheme({
  palette: {
    secondary: {
      light: '#61D2AA',
      main: '#61D2AA',
      dark: '#00704F',
    },
  },
});

const db = firebase.firestore();

// VARIABLES

let bdd = {
  apprentices: {
    key: 'apprentices',
    datas: {},
  },
  companies: {
    key: 'companies',
    datas: {},
  },
  contracts: {
    key: 'contracts',
    datas: {},
  },
  links: {
    key: 'links',
    datas: {},
  },
  remunerations: {
    key: 'remunerations',
    datas: {},
  },
  formations: {
    key: 'formations',
    datas: {},
  },
};

let checkSlug = ['infos.id_company', 'infos.id_apprentice'];
let i = 0;

const Admin = (app) => {
  let login = app.isAuthed;
  let { slug } = useParams();
  let [perm, setPerm] = useState();
  let [, setUpdates] = useState({});
  let [isFetch, setFetch] = useState(false);
  const history = useHistory();

  // FONCTIONS

  useEffect(() => {
    getLink(bdd.links.key, i);
    // eslint-disable-next-line
  }, []);

  const getLink = (collection, i) => {
    db.collection(collection)
      .where(checkSlug[i], '==', slug)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          if (i >= 1) {
            history.push('/');
          } else {
            i++;
            getLink(collection, i);
          }
        } else {
          querySnapshot.forEach((doc) => {
            if (login) {
              setPerm(0);
            } else {
              let newPerm = i + 1;
              setPerm(newPerm);
            }
            slug = doc.data().infos.id_company;
            bdd[collection].datas = doc.data();
            getFirestoreDb(bdd.apprentices.key, slug);
            getFirestoreDb(bdd.companies.key, slug);
            getFirestoreDb(bdd.contracts.key, slug);
            getFirestoreDb(bdd.remunerations.key, slug);
            getFirestoreDb(bdd.formations.key, slug);
            getFirestoreDb(bdd.links.key, slug);
            checkUpdate(slug);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFirestoreDb = (collection, doc) => {
    db.collection(collection)
      .doc(doc)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let datas = doc.data();
          bdd[collection].datas = datas;
          setUpdates({});
        } else {
          history.push('/');
        }
      });
  };

  const checkUpdate = (slug) => {
    if (
      Object.keys(bdd.apprentices.datas).length !== 0 &&
      Object.keys(bdd.companies.datas).length !== 0 &&
      Object.keys(bdd.contracts.datas).length !== 0 &&
      Object.keys(bdd.remunerations.datas).length !== 0 &&
      Object.keys(bdd.formations.datas).length !== 0 &&
      Object.keys(bdd.links.datas).length !== 0 &&
      slug != ''
    ) {
      setFetch(true);
    } else {
      setFetch(false);
      setTimeout(() => {
        checkUpdate(slug);
      }, 500);
    }
  };

  return (
    <Box>
      <ThemeProvider theme={adTheme}>
        <div className="main-form">
          {isFetch ? (
            <div>
              <ManageForms
                apprentices={bdd.apprentices.datas}
                companies={bdd.companies.datas}
                contracts={bdd.contracts.datas}
                remunerations={bdd.remunerations.datas}
                formations={bdd.formations.datas}
                links={bdd.links.datas}
                slug={slug}
                path={bdd}
                perm={perm}
                isLogin={login}
              ></ManageForms>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </ThemeProvider>
    </Box>
  );
};

export default Admin;
