import React, { useState, useEffect } from 'react';
import Form from '../FormStructure';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import sectionClear from '../../../Templates/contract.json';
import mfs from '../../../Services/manageFormSection';

const Contract = ({ slug, datas, path, filter }) => {
  const [aprMasterState, setAprMaster] = useState();
  const collection = ['general'];
  const collectionAprMaster = ['apprentice_master_1', 'apprentice_master_2'];

  useEffect(() => {
    getActiveAprMaster();
  }, [datas]);

  const getActiveAprMaster = () => {
    let nbSectionActive = 0;
    for (var key in datas) {
      if (collectionAprMaster.includes(key)) {
        datas[key].section_settings.is_active && nbSectionActive++;
      }
    }
    setAprMaster(nbSectionActive);
  };

  const changeAprMaster = () => {
    if (aprMasterState == 1) {
      mfs.sectionAdd(datas, collectionAprMaster, aprMasterState, slug, path);
    } else {
      mfs.sectionRemove(datas, collectionAprMaster, aprMasterState, slug, path, sectionClear);
    }
  };

  return (
    <div>
      {collection.map((item) => (
        <Form key={item} newDatas={datas[item]} path={path} objectName={item} slug={slug} filter={filter} formEdit={true}></Form>
      ))}
      {aprMasterState >= 1 && (
        <Form
          key={collectionAprMaster[0]}
          newDatas={datas[collectionAprMaster[0]]}
          path={path}
          objectName={collectionAprMaster[0]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      <div className='add-hide-section' onClick={changeAprMaster}>
        {aprMasterState == 1 ? <AddRoundedIcon className='ahs-icon add' /> : <DeleteOutlineRoundedIcon className='ahs-icon delete' />}
        {aprMasterState == 1 ? (
          <p className='ahs-title add'>Ajouter un second maître d'apprentissage</p>
        ) : (
          <p className='ahs-title delete'>Supprimer le second maître d'apprentissage</p>
        )}
      </div>
      {aprMasterState >= 2 && (
        <Form
          key={collectionAprMaster[1]}
          newDatas={datas[collectionAprMaster[1]]}
          path={path}
          objectName={collectionAprMaster[1]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
    </div>
  );
};

export default Contract;
