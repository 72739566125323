import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import fsDatabase from "../../../../../Services/firestoreFunctions";
import { secondaryApp } from "../../../../../firebase";
import { useSnackbar } from "notistack";

const initialState = {
  firstname: "",
  lastname: "",
  picture: "",
  main_perm: 0,
  school_perms: [],
  city_perms: [],
};

const ManageUsers = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState(initialState);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [perms, setPerms] = useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleCreate = () => {
    secondaryApp
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await fsDatabase.dbWrite("users", user.uid, state);

        enqueueSnackbar("Nouvel utilisateur créé avec succès", {
          variant: "success",
        });

        setState(initialState);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("error", errorCode, errorMessage);
        enqueueSnackbar(`Erreur ${errorCode} : ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const getPerms = async () => {
      const response = await fsDatabase.dbGet("perms", "perms");
      setPerms(response);
    };

    getPerms();
  }, []);

  return (
    <div className="manage-user">
      <h1>Ajout d'utilisateur</h1>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            placeholder="Prénom"
            name="firstname"
            fullWidth
            value={state.firstname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            placeholder="Nom"
            name="lastname"
            fullWidth
            value={state.lastname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            placeholder="eMail"
            name="email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            placeholder="password"
            type="password"
            name="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            placeholder="Photo"
            name="picture"
            fullWidth
            value={state.picture}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="main-perm-select-label">
              Permission principale
            </InputLabel>
            <Select
              labelId="main-perm-select-label"
              id="main-perm-select"
              name="main_perm"
              value={state.main_perm}
              onChange={handleChange}
              fullWidth
            >
              {perms &&
                perms.main_perm.map((perm, index) => (
                  <MenuItem value={index} key={perm}>
                    {perm}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="school-perm-select-label">
              Permission d'école
            </InputLabel>
            <Select
              labelId="school-perm-select-label"
              id="school-perm-select"
              name="school_perms"
              value={state.school_perms}
              onChange={handleChange}
              fullWidth
              multiple
            >
              {perms &&
                perms.school_perms.map((perm, index) => (
                  <MenuItem value={index} key={perm}>
                    {perm}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="city-perm-select-label">
              Permission de ville
            </InputLabel>
            <Select
              labelId="city-perm-select-label"
              id="city-perm-select"
              name="city_perms"
              value={state.city_perms}
              onChange={handleChange}
              fullWidth
              multiple
            >
              {perms &&
                perms.city_perms.map((perm, index) => (
                  <MenuItem value={index} key={perm}>
                    {perm}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button
        style={{ marginTop: 30 }}
        variant="contained"
        onClick={handleCreate}
      >
        Ajouter cet utilisateur
      </Button>
    </div>
  );
};

export default ManageUsers;
