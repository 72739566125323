import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Apprentice from './Forms/Apprentice';
import Company from './Forms/Company';
import Contract from './Forms/Contract';
import Remuneration from './Forms/Remuneration';
import Formation from './Forms/Formation';
import MenuTabs from './Menu/MenuTabs';
import InfoBar from './Menu/InfoBar';
import ManageProgress from './Progress/ManageProgress';
import FilterContext from '../../Context/Filter';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import AddSiret from './AddSiret';
import './form.scss';
import FormMenu from './Menu/FormMenu';
import Drawer from '@material-ui/core/Drawer';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import Button from '@material-ui/core/Button';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import EditFormClose from './EditFormClose';
import { ContactSupportOutlined } from '@material-ui/icons';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

let progressFinished = false;

const ManageForms = ({
  path,
  slug,
  perm,
  apprentices,
  companies,
  contracts,
  remunerations,
  formations,
  links,
  isLogin,
}) => {
  const [tab, setTab] = useState(
    slug == links.infos.id_company
      ? 'Entreprise'
      : isLogin
      ? 'Entreprise'
      : 'Etudiant',
  );
  const [progressDatas, setProgressDatas] = useState();
  const [filter, setFilter] = useState(0);
  const [siret, setSiret] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [innerWidth, setWidth] = useState(window.innerWidth);
  const [innerHeight, setHeight] = useState(window.innerHeight);
  const [goTop, setTop] = useState(false);
  const formArea = useRef(null);
  const formEdit = links.infos.form_edit;
  const [displayMenu, setDisplayMenu] = useState(false);
  const isCompany = slug === links.infos.id_company;
  const history = useHistory();

  const tabsValue = [
    {
      tabPerm: 1,
      label: 'Entreprise',
      name: 'companies',
    },
    {
      tabPerm: 1,
      label: 'Contrat',
      name: 'contracts',
    },
    {
      tabPerm: 2,
      label: 'Etudiant',
      name: 'apprentices',
    },
    {
      tabPerm: 1,
      label: 'Remuneration',
      name: 'remunerations',
    },
    {
      tabPerm: 1,
      label: 'Info CFA',
      name: 'formations',
    },
  ];

  const tabsToDisplay = tabsValue.filter((tab) => tab.tabPerm >= perm);

  useEffect(() => {
    !isCompany && isLogin && history.push('/form/' + links.infos.id_company);
  });

  useEffect(() => {
    getSiret();
  }, [companies]);

  const getSiret = () => {
    companies.general.company_siret.value.length > 0
      ? setSiret(true)
      : setSiret(false);
  };

  function handleChange(tabValue) {
    setTab(tabValue);
  }

  const filterValue = {
    filter,
    changeFilter: setFilter,
  };

  /* Menu */

  useEffect(() => {
    function getWidth() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, []);

  useEffect(() => {
    innerWidth <= 900 ? setDisplayMenu(true) : setDisplayMenu(false);
  }, [innerWidth]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenMenu(open);
  };

  const setResponsiveMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  /* Go to top */

  useEffect(() => {
    if (formArea.current != null) {
      formArea.current.scrollTo(0, 0);
    }
  }, [tab, goTop]);

  const goToTop = () => {
    setTop(!goTop);
  };

  /* Change Tabs */

  const changeTab = (eventValue) => {
    let lastIndex = tabsToDisplay.length - 1;
    let tabIndex = tabsToDisplay.findIndex(
      (tabActive) => tabActive.label === tab,
    );
    let newTabValue;
    if (eventValue == 'add') {
      tabIndex != lastIndex
        ? (newTabValue = tabsToDisplay[tabIndex + 1].label)
        : (newTabValue = tabsToDisplay[0].label);
    } else {
      tabIndex != 0
        ? (newTabValue = tabsToDisplay[tabIndex - 1].label)
        : (newTabValue = tabsToDisplay[lastIndex].label);
    }
    setTab(newTabValue);
  };

  /* Progress */

  let filterFieldsDatas = [
    contracts,
    companies,
    apprentices,
    remunerations,
    formations,
  ];
  // Remuneration à changer dans les json
  let filterFieldsName = [
    'contracts',
    'companies',
    'apprentices',
    'remunerations',
    'formations',
  ];
  let filtersName = ['cerfa', 'convention'];

  useEffect(() => {
    getProgress();
  }, [apprentices, companies, contracts, remunerations]);

  const getProgress = () => {
    let visibleFields = [];
    filterFieldsDatas.map((collection) => {
      let collectionVisibleField = [];
      for (const [, value] of Object.entries(collection)) {
        let fieldsDatas = value;
        if (fieldsDatas.section_settings.is_active) {
          for (const [key, value] of Object.entries(fieldsDatas)) {
            if (key != 'section_settings') {
              if (value.filter.length >= 2) {
                collectionVisibleField.push(value);
              }
            }
          }
        }
      }
      visibleFields.push(collectionVisibleField);
    });
    filterProgress(visibleFields);
  };

  const filterProgress = (visibleFields) => {
    let tempProgressDatas = {
      filters: {},
    };
    visibleFields.map((totalFields, i) => {
      let validFields = totalFields.filter((field) => field.is_valid == true);
      let nonValidFields = totalFields.filter(
        (field) => field.is_valid == false,
      );
      // console.log(nonValidFields)

      tempProgressDatas = {
        ...tempProgressDatas,
        [filterFieldsName[i]]: {
          is_valid: validFields.length,
          total: totalFields.length,
          filters: {
            // is_valid: 0,
            // total: 0,
          },
        },
        filters: {
          ...tempProgressDatas.filters,
        },
      };
      // console.log(nonValidFields);

      filtersName.map((filterName, filterIndex) => {
        let filterTotal = totalFields.filter((field) =>
          field.filter.includes(filterIndex + 1),
        ).length;
        let filterFilled = validFields.filter((field) =>
          field.filter.includes(filterIndex + 1),
        ).length;

        tempProgressDatas = {
          ...tempProgressDatas,
          [filterFieldsName[i]]: {
            ...tempProgressDatas[filterFieldsName[i]],
            filters: {
              ...tempProgressDatas[filterFieldsName[i]].filters,
              [filterName]: {
                is_valid: filterFilled,
                total: filterTotal,
              },
              // is_valid : tempProgressDatas[filterFieldsName[i]].filters.is_valid + filterFilled,
              // total : tempProgressDatas[filterFieldsName[i]].filters.total + filterTotal
            },
          },
          filters: {
            ...tempProgressDatas.filters,
            [filterName]: {
              is_valid: tempProgressDatas.filters[filterName]
                ? tempProgressDatas.filters[filterName].is_valid + filterFilled
                : filterFilled,
              total: tempProgressDatas.filters[filterName]
                ? tempProgressDatas.filters[filterName].total + filterTotal
                : filterTotal,
              percent:
                tempProgressDatas.filters[filterName] &&
                (tempProgressDatas.filters[filterName].total + filterTotal ==
                tempProgressDatas.filters[filterName].is_valid + filterFilled
                  ? 100
                  : Math.round(
                      ((tempProgressDatas.filters[filterName].is_valid +
                        filterFilled) /
                        (tempProgressDatas.filters[filterName].total +
                          filterTotal)) *
                        100,
                    )),
            },
          },
        };
        // console.log(tempProgressDatas)
      });
      setProgressDatas(tempProgressDatas);
    });
    if (
      tempProgressDatas.filters.cerfa.percent >= 90 &&
      tempProgressDatas.filters.convention.percent >= 90
    ) {
      progressFinished = true;
    } else {
      progressFinished = false;
    }
  };

  // const closeEdit = () => {

  // }

  return (
    <FilterContext.Provider value={filterValue}>
      {progressDatas && (
        <div className="ctp-form">
          {innerWidth <= 1200 && formEdit ? (
            <Drawer
              anchor="left"
              open={openMenu}
              onClose={toggleDrawer('left', false)}
            >
              <FormMenu
                identity={links.identity}
                infos={links.infos}
                progressDatas={progressDatas.filters}
                progressFinished={progressFinished}
                formEdit={formEdit}
                slug={slug}
                isLogin={isLogin}
                height={innerHeight}
              />
            </Drawer>
          ) : (
            <FormMenu
              identity={links.identity}
              infos={links.infos}
              progressDatas={progressDatas.filters}
              progressFinished={progressFinished}
              formEdit={formEdit}
              slug={slug}
              isLogin={isLogin}
              height={innerHeight}
            />
          )}
          {(siret || slug == links.infos.id_apprentice) && formEdit ? (
            <div ref={formArea} className="form-dashboard">
              {/* <div className='header'>
            <Container className='ctn-header' maxWidth='lg'>
              <InfoBar identity={links.identity} infos={links.infos} />
            </Container>
          </div> */}
              <div className="form-section">
                <TabContext value={tab}>
                  <div className={'cpn-tabs tabs-open-' + displayMenu}>
                    {innerWidth <= 1200 && formEdit && (
                      <div
                        className="menu-open"
                        onClick={toggleDrawer('left', true)}
                      >
                        Menu
                      </div>
                    )}
                    <MenuTabs
                      perm={perm}
                      setTab={handleChange}
                      tab={tab}
                      tabsToDisplay={tabsToDisplay}
                      switchTab={changeTab}
                      progress={progressDatas}
                      isCompany={isCompany}
                      isLogin={isLogin}
                    />
                    <div className="displayMenu" onClick={setResponsiveMenu}>
                      {displayMenu ? (
                        <KeyboardArrowDownRoundedIcon className="icon" />
                      ) : (
                        <KeyboardArrowUpRoundedIcon className="icon" />
                      )}
                    </div>
                  </div>
                  <div className="cpn-dashboard">
                    <Container className="form-content" maxWidth="lg">
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <TabPanel value="Entreprise">
                            <Company
                              datas={companies}
                              path={path.companies.key}
                              slug={links.infos.id_company}
                            />
                          </TabPanel>
                          <TabPanel value="Contrat">
                            <Contract
                              datas={contracts}
                              path={path.contracts.key}
                              slug={links.infos.id_company}
                            />
                          </TabPanel>
                          <TabPanel value="Etudiant">
                            <Apprentice
                              datas={apprentices}
                              path={path.apprentices.key}
                              slug={links.infos.id_company}
                            />
                          </TabPanel>
                          <TabPanel value="Remuneration">
                            <Remuneration
                              datas={remunerations}
                              path={path.remunerations.key}
                              slug={links.infos.id_company}
                            />
                          </TabPanel>
                          <TabPanel value="Info CFA">
                            <Formation
                              datas={formations}
                              path={path.formations.key}
                              slug={links.infos.id_company}
                            />
                          </TabPanel>
                        </Grid>
                      </Grid>
                    </Container>
                    <div className="ctn-go-top" onClick={goToTop}>
                      <div className="go-top">
                        <ArrowUpwardRoundedIcon className="go-top-icon" />
                        <h2 className="go-top-title">Haut de page</h2>
                      </div>
                    </div>
                  </div>
                </TabContext>
              </div>
              <div className="dashboard-footer">
                <div className="save">
                  <SaveRoundedIcon className="save-icon" />
                  <p className="save-text">
                    Les modifications sont enregistrées automatiquement
                  </p>
                </div>
                {(isCompany || isLogin) && (
                  <div className="next">
                    <Button
                      variant="contained"
                      className="next-btn"
                      onClick={() => changeTab('add')}
                    >
                      Suivant
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : formEdit ? (
            <AddSiret
              companies={companies}
              path={path.companies.key}
              slug={slug}
            />
          ) : (
            <EditFormClose
              datas={[
                companies,
                contracts,
                apprentices,
                remunerations,
                formations,
              ]}
              slug={slug}
              links={links}
              progress={progressDatas}
              formEdit={formEdit}
              isCompany={isCompany}
              isLogin={isLogin}
            />
          )}
        </div>
      )}
    </FilterContext.Provider>
  );
};
export default ManageForms;
