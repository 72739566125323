import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

class Student extends Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            surname: ''
        };
    }

    handleChange = (event) => {
        let value = event.target.value
        const id = event.target.id
        if (id === 'firstname') {
            if (value.length >= 1) {
              let words = value.split(' ')
              for (let i = 0; i < words.length; i++) {
                console.log(words.length)
                words[i].length >= 1 &&
                  (words[i] = words[i][0].toUpperCase() + words[i].substr(1))
              }
              value = words.join(' ')
            }
          } else if (id === 'lastname') {
            value = value.toUpperCase()
          }
        this.props.onUpdate(id, value)
        this.setState({[id] : value});
        event.preventDefault();
    };


    render() {
        return (
          <form noValidate autoComplete="off">
            <Grid className="student-ctn" container spacing={3}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="firstname"
                  label="Prénom"
                  variant="outlined"
                  fullWidth={true}
                  onChange={this.handleChange}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="lastname"
                  label="Nom"
                  variant="outlined"
                  fullWidth={true}
                  onChange={this.handleChange}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </Grid>
            </Grid>
          </form>
        )
    }
}

export default Student;