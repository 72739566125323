import React, { useState, useEffect } from 'react';
import Form from '../FormStructure';
import sectionClear from '../../../Templates/company.json';
import mfs from '../../../Services/manageFormSection';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const Company = ({ slug, datas, path, filter }) => {
  const [adressState, setAdress] = useState(1);
  const [copyAdressDatas, setCopyAdress] = useState(datas);
  const adressMin = 1;
  const adressMax = 2;
  const collection = ['general'];
  const collectionAdress = ['company_adress', 'company_billing_adress'];
  const collectionRepresentant = ['company_legal_representant', 'company_admin_representant'];
  const representantMin = 1;
  const representantMax = 2;
  const [representantState, setRepresentant] = useState(1);
  const [copyRepresentantDatas, setCopyRepresentant] = useState(datas);

  useEffect(() => {
    getActive();
  }, [datas]);

  const getActive = () => {
    let activeAdress = 0;
    let activeRepresentant = 0;
    for (var key in datas) {
      if (collectionAdress.includes(key)) {
        datas[key].section_settings.is_active && activeAdress++;
      }
      if (collectionRepresentant.includes(key)) {
        datas[key].section_settings.is_active && activeRepresentant++;
      }
    }
    setAdress(activeAdress);
    setRepresentant(activeRepresentant);
  };

  const changeAdress = () => {
    if (adressState == adressMin) {
      let clearDatas = {
        ...datas,
        [collectionAdress[adressState]]: sectionClear[collectionAdress[adressState]],
      };
      // setCopyAdress(clearDatas);
      mfs.sectionAdd(clearDatas, collectionAdress, adressState, slug, path);
    } else if (adressState == adressMax) {
      mfs.sectionRemove(datas, collectionAdress, adressState, slug, path, sectionClear);
    }
  };

  const changeRepresentant = () => {
    if (representantState == representantMin) {
      let clearDatas = {
        ...datas,
        [collectionRepresentant[representantState]]: sectionClear[collectionRepresentant[representantState]],
      };
      // setCopyAdress(clearDatas);
      mfs.sectionAdd(clearDatas, collectionRepresentant, representantState, slug, path);
    } else if (representantState == representantMax) {
      mfs.sectionRemove(datas, collectionRepresentant, representantState, slug, path, sectionClear);
    }
  };

  return (
    <div>
      {collection.map((item) => (
        <Form key={item} newDatas={datas[item]} path={path} objectName={item} slug={slug} filter={filter} formEdit={true}></Form>
      ))}
      {adressState >= 1 && (
        <Form
          key={collectionAdress[0]}
          copyBdd={{ active: adressState == 1 ? true : false, collection: [collectionAdress[1]] }}
          newDatas={datas[collectionAdress[0]]}
          path={path}
          objectName={collectionAdress[0]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      <FormControl
        className={
          adressState == 1
            ? 'display-section copy close form-parent-lock-' +
              datas[collectionAdress[0]].section_settings.is_locked +
              ' child-lock-' +
              datas[collectionAdress[1]].section_settings.is_locked
            : 'display-section copy open form-parent-lock-' +
              datas[collectionAdress[0]].section_settings.is_locked +
              ' child-lock-' +
              datas[collectionAdress[1]].section_settings.is_locked
        }
      >
        <FormLabel className='display-section-title'>Est-ce que l'adresse de facturation est différente ?</FormLabel>
        <RadioGroup className='display-section-radio' row value={adressState} onChange={changeAdress}>
          <FormControlLabel className='display-section-radio-label' key='yes' value={2} control={<Radio />} label='Oui' />
          <FormControlLabel className='display-section-radio-label' key='no' value={1} control={<Radio />} label='Non' />
        </RadioGroup>
      </FormControl>
      {adressState >= 2 && (
        <Form
          key={collectionAdress[1]}
          newDatas={datas[collectionAdress[1]]}
          path={path}
          objectName={collectionAdress[1]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      {representantState >= 1 && (
        <Form
          key={collectionRepresentant[0]}
          copyBdd={{ active: representantState == 1 ? true : false, collection: [collectionRepresentant[1]] }}
          newDatas={datas[collectionRepresentant[0]]}
          path={path}
          objectName={collectionRepresentant[0]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      <FormControl
        className={
          representantState == 1
            ? 'display-section copy close form-parent-lock-' +
              datas[collectionRepresentant[0]].section_settings.is_locked +
              ' child-lock-' +
              datas[collectionRepresentant[1]].section_settings.is_locked
            : 'display-section copy open form-parent-lock-' +
              datas[collectionRepresentant[0]].section_settings.is_locked +
              ' child-lock-' +
              datas[collectionRepresentant[1]].section_settings.is_locked
        }
      >
        <FormLabel className='display-section-title'>Est-ce que le contact pour la facturation est different ?</FormLabel>
        <RadioGroup className='display-section-radio' row value={representantState} onChange={changeRepresentant}>
          <FormControlLabel className='display-section-radio-label' key='yes' value={2} control={<Radio />} label='Oui' />
          <FormControlLabel className='display-section-radio-label' key='no' value={1} control={<Radio />} label='Non' />
        </RadioGroup>
      </FormControl>
      {representantState >= 2 && (
        <Form
          key={collectionRepresentant[1]}
          newDatas={datas[collectionRepresentant[1]]}
          path={path}
          objectName={collectionRepresentant[1]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
    </div>
  );
};

export default Company;
