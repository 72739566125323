import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [auth, setAuth] = useState(() => {
    const localData = localStorage.getItem('auth');
    return localData ? JSON.parse(localData) : false;
  });

  useEffect(() => {
    localStorage.setItem('auth', JSON.parse(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
