import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const isProd = process.env.REACT_APP_ENV === 'production';

const firebaseConfig = {
  apiKey: isProd
    ? process.env.REACT_APP_API_KEY
    : process.env.REACT_APP_DEV_API_KEY,
  authDomain: isProd
    ? process.env.REACT_APP_AUTH_DOMAIN
    : process.env.REACT_APP_DEV_AUTH_DOMAIN,
  projectId: isProd
    ? process.env.REACT_APP_PROJECT_ID
    : process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: isProd
    ? process.env.REACT_APP_STORAGE_BUCKET
    : process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: isProd
    ? process.env.REACT_APP_MESSAGING_SENDER_ID
    : process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: isProd
    ? process.env.REACT_APP_APP_ID
    : process.env.REACT_APP_DEV_APP_ID,
  measurementId: isProd
    ? process.env.REACT_APP_MEASUREMENT_ID
    : process.env.REACT_APP_DEV_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

// The purpose of this secondary app is to not sign in when creating a new user
const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
export { secondaryApp };

export default firebase;
