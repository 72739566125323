import React from 'react';
import Button from '@material-ui/core/Button';
const Progress = ({ progress }) => {
  // <Form key={item} newDatas={datas[item]} path={path} objectName={item} slug={slug} filter={filter}></Form>;

  // let percentFilled = Math.round((progress.cerfaTotal / progress.cerfaFilled) * 100);
  let filters = [];
  // console.log(progress)

  for (const [key, value] of Object.entries(progress)) {
    filters.push({
      name: key,
      percent: value.percent,
    });
  }

  return (
    <div className='progress'>
      {filters.map((item) => (
        <div className='ctn-progress'>
          <div className='progress-title'>
            <div className={'pin ' + item.name}></div>
            <p className={'title ' + item.name}>{item.name}</p>
          </div>
          <div className='progress-achievement'>
            <div className='progress-visual'>
              <div className='progress-bar'>
                <div className={'bar ' + item.name} style={{width: item.percent + '%'}}></div>
              </div>
              <div className='progress-percent'>
              {item.percent} <span class='percent-symbole'>%</span>
              </div>
            </div>
            <div className='progress-number'>
              {/* <p className='number'>
                {item.filled} / {item.total}
              </p> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Progress;
