import React, { useState } from 'react';
import Form from './FormStructure';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import imgValidation from '../../Assets/image-validation.png';
import fsDatabase from '../../Services/firestoreFunctions';

const EditFormClose = ({
  datas,
  slug,
  links,
  progress,
  formEdit,
  isLogin,
  isCompany,
}) => {
  let path = [
    'companies',
    'contracts',
    'apprentices',
    'remunerations',
    'formations',
  ];
  let title = [
    'Entreprise',
    'Contrat',
    'Étudiant',
    'Rémunération',
    'Formation',
  ];
  let mainCollection = [
    [
      'general',
      'company_adress',
      'company_billing_adress',
      'company_admin_representant',
      'company_legal_representant',
    ],
    ['general', 'apprentice_master_1', 'apprentice_master_2'],
    ['general', 'apprentice_adress', 'apprentice_tutor', 'situation'],
    [
      'contract_date_duration',
      'apprentice_salary',
      'first_year',
      'second_year',
      'third_year',
      'fourth_year',
    ],
    [
      'cfa',
      'adress_cfa',
      'school_info',
      'formation_info',
      'cost_info',
      'rgpd_info',
    ],
  ];

  const [expanded, setExpanded] = useState(false);

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const changeFormEdit = () => {
    if (slug === links.infos.id_company) {
      let fsDatas = {
        infos: {
          form_edit: !formEdit,
        },
      };
      fsDatabase.dbWrite('links', slug, fsDatas);
    }
  };

  console.log(progress['apprentices'].is_valid);

  return (
    <div className="cpn-edit-form-close">
      {isCompany ? (
        <div className="header">
          <img className="image-validation" src={imgValidation}></img>
          <h1 className="title">On y est presque !</h1>
          <h2 className="subtitle">
            Vos données vont être vérifiées et validées par le service des
            relations entreprises
          </h2>
          <h2 className="correction">
            En voici une synthèse, si vous constatez une erreur,{' '}
            <span class="link" onClick={changeFormEdit}>
              vous pouvez encore les modifier
            </span>
          </h2>
        </div>
      ) : (
        <div className="header">
          <img className="image-validation" src={imgValidation}></img>
          <h1 className="title">Votre entreprise à cloturé le formulaire</h1>
          <h2 className="subtitle">
            Les données vont être vérifiées et validées par le service des
            relations entreprises
          </h2>
          <h2 className="correction">
            Si vous constatez une erreur, n'hésitez pas à contacter votre
            entreprise
          </h2>
        </div>
      )}
      {progress != {} && (
        <div className="ctn-efc">
          <div className="efc-forms">
            {slug == links.infos.id_company ? (
              path.map((pathName, i) => (
                <Accordion
                  className="accordion-ctn"
                  expanded={expanded === 'panel' + path[i]}
                  onChange={handleChangePanel(`panel` + path[i])}
                >
                  {progress[pathName] && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="left-col">
                        {progress[pathName].is_valid ==
                        progress[pathName].total ? (
                          <DoneRoundedIcon className={'indication icon-true'} />
                        ) : (
                          <CloseRoundedIcon
                            className={'indication icon-false'}
                          />
                        )}
                        <p className="title">{title[i]}</p>
                      </div>
                      <div className="subtitle">
                        <p className="progress total-state">
                          <div className="pin"></div>
                          <span className="valid">
                            {progress[pathName].is_valid}
                          </span>{' '}
                          /{' '}
                          <span className="total">
                            {progress[pathName].total}
                          </span>
                        </p>
                        {/* <p className='progress cerfa-state'><div className='pin'></div><span className='valid'>{progress[pathName].filters.cerfa.is_valid}</span> / <span className='total'>{progress[pathName].filters.cerfa.total}</span></p>
                  <p className='progress convention-state'><span className='valid'>{progress[pathName].filters.convention.is_valid}</span> / <span className='total'>{progress[pathName].filters.convention.total}</span></p> */}
                      </div>
                    </AccordionSummary>
                  )}
                  <AccordionDetails>
                    {mainCollection[i].map((item) => (
                      <div>
                        <Form
                          key={item}
                          newDatas={datas[i][item]}
                          path={pathName}
                          objectName={item}
                          slug={slug}
                          formEdit={false}
                        ></Form>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Accordion
                className="accordion-ctn"
                expanded={expanded === 'panelapprentices'}
                onChange={handleChangePanel('panelapprentices')}
              >
                {progress['apprentices'] && (
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="left-col">
                      {progress['apprentices'].is_valid ==
                      progress['apprentices'].total ? (
                        <DoneRoundedIcon className={'indication icon-true'} />
                      ) : (
                        <CloseRoundedIcon className={'indication icon-false'} />
                      )}
                      <p className="title">{title[2]}</p>
                    </div>
                    <div className="subtitle">
                      <p className="progress total-state">
                        <div className="pin"></div>
                        <span className="valid">
                          {progress['apprentices'].is_valid}
                        </span>{' '}
                        /{' '}
                        <span className="total">
                          {progress['apprentices'].total}
                        </span>
                      </p>
                      {/* <p className='progress cerfa-state'><div className='pin'></div><span className='valid'>{progress[pathName].filters.cerfa.is_valid}</span> / <span className='total'>{progress[pathName].filters.cerfa.total}</span></p>
                  <p className='progress convention-state'><span className='valid'>{progress[pathName].filters.convention.is_valid}</span> / <span className='total'>{progress[pathName].filters.convention.total}</span></p> */}
                    </div>
                  </AccordionSummary>
                )}
                <AccordionDetails>
                  {mainCollection[2].map((item) => (
                    <Form
                      key={item}
                      newDatas={datas[2][item]}
                      path={'apprentices'}
                      objectName={item}
                      slug={slug}
                      formEdit={false}
                    ></Form>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditFormClose;
