import React, { useState, useEffect, useContext, useCallback } from "react";
import fsDatabase from "../../Services/firestoreFunctions";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";
import FilterContext from "../../Context/Filter";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
// import InputAdornment from '@material-ui/core/InputAdornment';
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import { useSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';

const Form = ({
  newDatas,
  slug,
  path,
  objectName,
  copyBdd,
  singleInput,
  formEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { filter } = useContext(FilterContext);
  let [fields, setFields] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(null);
  let actualFocus = null;
  const [datas, setDatas] = useState(newDatas);
  let [, setUpdates] = useState({});
  let fsDatas;
  let inputs = [];
  let lock = !singleInput ? newDatas.section_settings.is_locked : false;
  let section_active = !singleInput && newDatas.section_settings.is_active
  let isVisible = !singleInput && newDatas.section_settings.is_visible
  // UPDATE BDD
  const handleChange = (event) => {
    if (!lock) {
      const key = event.target.name;
      const value = event.target.value;
      if (value > 0 || value.length > 0) {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const handleChangeInput = (event) => {
    console.log(event.target)
    if (!lock) {
      const key = event.target.name;
      const value = event.target.value;
      if (value.length > 0) {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const handleChangeDate = (event, dateKey) => {
    if (!lock) {
      const key = dateKey;
      const value = event ? moment(event).format("DD/MM/YYYY") : "";
      if (value != "00-00-0000" && value != "" && value != "Invalid date") {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const handleChangeCheckbox = (event) => {
    if (!lock) {
      const key = event.target.name;
      let value;
      event.target.checked ? (value = true) : (value = false);
      if (value == true) {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const alertUser = useCallback((e) => {
    setCurrentFocus(null);
    document.activeElement.blur();
    e = e || window.event;
    unlockOnLeaving(e);
    e.preventDefault();
    e.returnValue = "";
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentFocus !== null) {
      window.addEventListener("beforeunload", alertUser);
    } else {
      window.removeEventListener("beforeunload", alertUser);
    }
  }, [currentFocus, alertUser]);

  const unlockOnLeaving = async (e) => {
    if (currentFocus === null) return;
    e.preventDefault();
    e.returnValue = "";
    let tempDatas = await {
      ...datas,
      [currentFocus]: {
        ...datas[currentFocus],
        is_locked: false,
      },
    };
    await setDatas(tempDatas);
    fsDatas = { [objectName]: tempDatas };
    await fsDatabase.dbWrite(path, slug, fsDatas);
  };

  const mergeData = (key, value, merge, valid) => {
    let tempDatas = {
      ...datas,
      [key]: {
        ...datas[key],
        value: value,
      },
    };
    // console.log(tempDatas[key]);
    setDatas(tempDatas);
    if (merge && formEdit) {
      tempDatas = {
        ...tempDatas,
        [key]: {
          ...tempDatas[key],
          is_valid: valid,
        },
      };
      if (copyBdd && copyBdd.active) {
        let copiedDatas = copyDatas(tempDatas);
        tempDatas = {
          [objectName]: tempDatas,
          [copyBdd.collection]: copiedDatas,
        };
        fsDatas = tempDatas;
      } else {
        fsDatas = { [objectName]: tempDatas };
      }
      updateFirestore();
    }
  };

  const updateFirestore = () => {
    if (formEdit) {
      fsDatabase.dbWrite(path, slug, fsDatas);
    }
  };

  const blur = (event) => {
    const key = event.target.name;
    setCurrentFocus(null);
    const value = event.target.value;
    if (value.length > 0) {
      mergeData(key, value, true, true);
    } else {
      mergeData(key, value, true, false);
    }
  };

  const focus = (event) => {
    // const key = event.target.name;
    // setCurrentFocus(key);
    // let tempDatas = {
    //   ...datas,
    //   [key]: {
    //     ...datas[key],
    //     is_locked: true,
    //   },
    // };
    // setDatas(tempDatas);
    // fsDatas = { [objectName]: tempDatas };
    // fsDatabase.dbWrite(path, slug, fsDatas);
  };

  const handleLock = () => {
    let tempDatas = datas;
    let nobodyModify = [];
    if (!newDatas['section_settings'].is_locked) {
      for (const [id] of Object.entries(newDatas)) {
        if (newDatas[id].is_locked) {
          if (
            !nobodyModify.filter(
              (item) =>
                !item.includes('apprentice_last_name') ||
                item.includes('apprentice_first_name')
            )
          ) {
            nobodyModify.push(id)
          }
        }
      }
    }
    console.log(nobodyModify)
    if (nobodyModify.length == 0 && path != 'formations') {
      for (const [id] of Object.entries(newDatas)) {
        if (id != 'apprentice_last_name' && id != 'apprentice_first_name') {
          console.log(id)
          tempDatas = {
            ...tempDatas,
            [id]: {
              ...tempDatas[id],
              is_locked: !lock,
            },
          }
        }
      }
      setDatas(tempDatas)
      fsDatas = { [objectName]: tempDatas }
      fsDatabase.dbWrite(path, slug, fsDatas)
      if (newDatas['section_settings'].is_locked) {
        enqueueSnackbar('La section a bien été déverrouillée', {
          variant: 'success',
        })
      } else {
        enqueueSnackbar('Verrouillage de la section', {
          variant: 'success',
        })
      }
    }
    if (path === "formations") {
      enqueueSnackbar(
        "Vous n'avez pas l'autorisation de modifier ces données",
        {
          variant: "error",
        }
      );
    } else if (nobodyModify.length > 0) {
      enqueueSnackbar("Un utilisateur est entrain de modifier les données", {
        variant: "warning",
      });
    }
  };

  // COPY DATAS
  const copyDatas = (tempDatas) => {
    let copiedDatas = {};
    if ("company_billing_adress" == copyBdd.collection) {
      copiedDatas = {
        company_billing_number_street: tempDatas.company_number_street,
        company_billing_complement: tempDatas.company_complement,
        company_billing_zip_code: tempDatas.company_zip_code,
        company_billing_city: tempDatas.company_city,
        company_billing_phone: tempDatas.company_phone,
        company_billing_mail: tempDatas.company_mail,
      };
    } else if ("company_admin_representant" == copyBdd.collection) {
      copiedDatas = {
        company_admin_civil: tempDatas.company_legal_representant_civil,
        company_admin_first_name:
          tempDatas.company_legal_representant_first_name,
        company_admin_last_name: tempDatas.company_legal_representant_last_name,
        company_admin_function: tempDatas.company_legal_representant_function,
        company_admin_mail: tempDatas.company_legal_representant_mail,
        company_admin_phone: tempDatas.company_legal_representant_phone,
      };
    }
    return copiedDatas;
  };

  // FUNCTIONS

  useEffect(() => {
    setDatas(newDatas);
  }, [newDatas]);

  useEffect(() => {
    getDatas();
  }, [filter, datas]);

  const getDatas = () => {
    for (const [id] of Object.entries(newDatas)) {
      if (id != "section_settings") {
        setInputs(datas[id], id);
      }
    }
  };

  const setInputs = (obj, id) => {
    if (obj.filter.includes(filter)) {
      let key = id;
      let objValue = obj;

      if (document.activeElement.name === key) {
        objValue.is_locked = false;
      }


      let filterHtml = [];
      let filterList = ["Cerfa", "Convention"];
      if (objValue.filter.length > 0) {
        objValue.filter.forEach((filterId) => {
          let filterName;
          if (filterId > 0) {
            filterName = filterList[filterId - 1];
            filterHtml.push(
              <div className="ctn-filter">
                <Tooltip
                  title={
                    "Champ nécessaire pour valider le formulaire : " +
                    filterName
                  }
                  arrow
                >
                  <div className={"cte-filter-pin " + filterName}></div>
                  {/* <p className={`cte-filter ${filterName}`}>{filterName}</p> */}
                </Tooltip>
              </div>
            );
          }
        });
      }
      if (formEdit) {
        if (objValue.type === 0) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <TextField
                  key={key}
                  helperText={objValue.helper_text && objValue.helper_text}
                  placeholder={objValue.placeholder}
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  autoComplete="no"
                  value={datas[key].value}
                  variant="outlined"
                  onFocus={focus}
                  onChange={handleChangeInput}
                  onBlur={blur}
                  fullWidth={true}
                  InputProps={{
                    startAdornment: objValue.start_adornment && (
                      <InputAdornment>
                        {objValue.start_adornment}
                      </InputAdornment>
                    ),
                    endAdornment: objValue.end_adornment && (
                      <InputAdornment>{objValue.end_adornment}</InputAdornment>
                    ),
                  }}
                  type={objValue.field_type && objValue.field_type}
                />
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 1) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <FormControl
                  key={key}
                  variant="outlined"
                  disabled={!singleInput ? objValue.is_locked : false}
                  fullWidth={true}
                >
                  <Select
                    key={key}
                    name={key}
                    value={datas[key].value}
                    onChange={handleChange}
                  >
                    {objValue.selects.map((index) => (
                      <MenuItem
                        key={index.display}
                        name={index.display}
                        value={index.display}
                      >
                        {index.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {objValue.helper_text && (
                    <FormHelperText>{objValue.helper_text}</FormHelperText>
                  )}
                </FormControl>
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 2) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <MuiPickersUtilsProvider
                  key={key}
                  locale={fr}
                  utils={DateFnsUtils}
                  format="dd/MM/yyyy"
                >
                  <KeyboardDatePicker
                    disabled={!singleInput ? objValue.is_locked : false}
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    margin="normal"
                    key={key}
                    value={
                      datas[key].value.length
                        ? moment(datas[key].value, "DD MM YYYY")
                        : null
                    }
                    format="dd/MM/yyyy"
                    onChange={(e) => handleChangeDate(e, key)}
                    invalidDateMessage={"La date sélectionnée n'est pas valide"}
                    autoOk={true}
                    KeyboardButtonProps={{
                      "aria-label": "Changer la date",
                    }}
                    fullWidth={true}
                    margin="none"
                    locale={"fr"}
                    cancelLabel={"Annuler"}
                    okLabel={"Valider"}
                  />
                </MuiPickersUtilsProvider>
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 3) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <FormControl
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  component="fieldset"
                  fullWidth={true}
                >
                  {objValue.helper_text && (
                    <FormHelperText>{objValue.helper_text}</FormHelperText>
                  )}
                  <RadioGroup
                    row
                    key={key}
                    label={objValue.label}
                    name={key}
                    value={datas[key].value}
                    onChange={handleChange}
                  >
                    {objValue.selects.map((index) => (
                      <FormControlLabel
                        key={index.display}
                        value={index.display}
                        control={<Radio />}
                        label={index.value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 4) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input">
                <FormControlLabel
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  component="fieldset"
                  fullWidth={true}
                  control={
                    <Checkbox
                      checked={objValue.value}
                      onChange={handleChangeCheckbox}
                      name={key}
                      color="primary"
                    />
                  }
                  label={
                  <div className="ctn-input-label">
                    <InputLabel>{objValue.label}</InputLabel>
                    {formEdit && <div className="input-filter">{filterHtml}</div>}
                  </div>
                  }
                />
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        }
      } else {

        inputs.push(
          <Grid
            key={key}
            item
            xs={12}
            sm={12 / objValue.col}
            order={objValue.order}
          >
            <div className={"ctn-input-label value-" + (objValue.value ? true : false) + ' is_require-' + (objValue.filter != 0 ? true : false)}>
            {/* {(!objValue.value && objValue.filter != 0) && (<ReportProblemRoundedIcon className={"icon"}/>)} */}
            <InputLabel>{objValue.label}</InputLabel>
            </div>
            <ArrowRightRoundedIcon />
            <div className={"ctn-input value-" + (objValue.value ? true : false) + ' is_require-' + (objValue.filter != 0 ? true : false)}>
            {console.log(objValue.label)}
              {console.log(objValue.value)}
              <p className={"input"}>{
              objValue.value ? (
                objValue.value === true || objValue.value === false ? (
                  objValue === true ? 'Vrai': 'Faux'
                ) : objValue.value 
              ) : 
              (objValue.filter != 0 ? (
              <div className='error'>
                <p className={"message"}>Champ requis manquant</p>
              </div>
              ) :
               'Champ optionnel non rempli')}</p></div>
            </Grid>
        );
      }
    }
    inputs.sort((a, b) => a.props.order - b.props.order);
    setFields(inputs);
  };

  return (
    <div className={"form-section lock-" + lock + " edit-form-" + formEdit}>
      {!singleInput && (formEdit == true || (isVisible != 0 || section_active == true)) ? (
        <Paper className="ctn-inputs" elevation={0}>
          <div className="section-header">
            <div className="title">
              {newDatas.section_settings.title && (
                <h2 className="section-title">
                  {newDatas.section_settings.title}
                </h2>
              )}
              {newDatas.section_settings.subtitle && (
                <h3 className="section-subtitle">
                  {newDatas.section_settings.subtitle}
                </h3>
              )}
            </div>
            {formEdit && <div className="lock-icon">
              <IconButton aria-label="lock-icon" onClick={handleLock}>
                {lock ? (
                  <LockRoundedIcon className="lock" />
                ) : (
                  <LockOpenRoundedIcon className="open" />
                )}
              </IconButton>
            </div>
            }
          </div>
          <Grid className="section-grid" container spacing={3}>
            {fields.length > 0 ? fields : <p>Pas de champs</p>}
          </Grid>
        </Paper>
      ) : (
        formEdit == true && (
        <div class="singleInput">
          {fields.length > 0 ? fields : <p>Pas de champs</p>}
        </div>
        )
      )}
    </div>
  );
};

export default Form;

// isLockded() {
//     let value
//     let type = event.type
//     if (type === "blur") {
//         value = false
//     } else if (type === "focus"){
//         value = true
//     }

//     let key = event.target.name
//     setState({
//         datas: {
//             ...datas,
//             [key]: {
//                 ...datas[key],
//                 is_locked : value
//             }
//         }
//     });
// }
