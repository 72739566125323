import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

const Levels = ({ levels, state, setState, lock, getData }) => {
  const [level, setLevel] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    getData(value);
    setLevel(value);
  };

  useEffect(() => {
    setState({ ...state, level: level });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  return (
    <Grid item md={3} sm={6} xs={12}>
      <FormControl
        variant="outlined"
        // onMouseDown={this.onFocus}
        disabled={lock}
        fullWidth={true}
      >
        <InputLabel id="demo-simple-select-outlined-label">Classe</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={level}
          onChange={handleChange}
          label="Classe"
        >
          {levels.map((index) => (
            <MenuItem key={index} value={index}>
              {index}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default Levels;
