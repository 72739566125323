import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./app.scss";

import AdminForm from "./Components/Form/AdminForm";
import AdminLogin from "./Components/Login/AdminLogin";
import Admin from "./Components/Admin/Admin";
import Home from "./Components/Home/Home";

import firebase from "firebase/app";
import Loader from "./Services/Loader";
import fsDatabase from "./Services/firestoreFunctions";

import { useSnackbar } from "notistack";

import "./app.scss";

let user;
let perms;

const Routing = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [auth, setAuthState] = useState({
    authenticated: false,
    initializing: true,
  });
  const [uid, setUid] = useState()

  useEffect(() => {
    window.$crisp = [];
      window.CRISP_WEBSITE_ID = "e01e3db7-e6b8-4963-8fdb-bc2a12b6d92f";
  
      (function() {
        var d = document;
        var s = d.createElement("script");
  
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
  }, []);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((authDatas) => {
        if (authDatas) {
          setUid(authDatas.uid)
          getUsersDatas(authDatas.uid);
        } else {
          setAuthState({
            authenticated: false,
            initializing: false,
          });
        }
      }),
    [setAuthState]
  );

  const getUsersDatas = async (uid) => {
    user = await fsDatabase.dbGet("users", uid);
    perms = await fsDatabase.dbGet("perms", "perms");

    if (Object.keys(user).length !== 0 && Object.keys(perms).length !== 0) {
      setAuthState({
        authenticated: true,
        initializing: false,
      });
    } else {
      enqueueSnackbar("Erreur lors de la récupération de l'utilisateur", {
        variant: "error",
      });
      setAuthState({
        authenticated: false,
        initializing: false,
      });
    }
  };

  return (
    <div className="main-layout">
      {auth.initializing ? (
        <Loader />
      ) : (
        <Router>
          <Switch>
            {/* <Route path='/login' exact component={AdminLogin} /> */}
            <Route
              path={["/form/:slug"]}
              exact
              render={(props) => (
                <AdminForm {...props} isAuthed={auth.authenticated} />
              )}
            />
            {auth.authenticated ? (
              <Route
                path={["/:slug"]}
                render={(props) => (
                  <Admin {...props} user={user} uid={uid} perms={perms} />
                )}
              />
            ) : (
              <Route exact path="/login" component={AdminLogin} />
            )}
            {auth.authenticated ? (
              <Redirect to="/contracts" />
            ) : (
              <Redirect to="/login" />
            )}
          </Switch>
        </Router>
      )}
    </div>
  );
};

export default Routing;
