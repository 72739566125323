import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../Assets/AD_Logo_White.svg';

import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import firebase from '../../../firebase';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";

let datas = {};

const AdminMenu = ({ user, perms }) => {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  let mainPerm = user.main_perm;

  const actionHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const actionHandleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnect = () => {
    firebase.auth().signOut().then(() => {
      enqueueSnackbar(`Déconnecté avec succès`, {
        variant: 'success',
      });
      history.push("/login");

    }).catch((error) => {
      enqueueSnackbar(`Erreur lors de la déconnection : ${error}`, {
        variant: 'error',
      });
    });
    
    actionHandleClose();
  };

  return (
    <div className='admin-menu'>
      <div className='admin-top'>
        <div className='header'>
          <div className='ctn-logo'>
            <img className='logo' src={logo} />
          </div>
        </div>
        <div className='nav'>
          {mainPerm <= 1 && (
            <div className='ctn-nav'>
              <NavLink to='/contracts' activeclasname='active' className='nav-head'>
                <DescriptionRoundedIcon className='nav-head-icon' />
                <div className='nav-head-title'>Contrats</div>
              </NavLink>
            </div>
          )}
          {mainPerm <= 0 && (
            <div className='ctn-nav sub-menu'>
              <div className='cte-sub-menu'>
                <NavLink to='/users' activeclassname='active' open={true} className='nav-head'>
                  <SettingsRoundedIcon className='nav-head-icon' />
                  <div className='nav-head-title'>Administration</div>
                </NavLink>
                <ExpandMoreRoundedIcon className='arrow' />
              </div>
              <div className='cte-nav'>
                <div className='border'></div>
                <div className='links'>
                  <NavLink to='/users' activeclassname='active' className='nav-link'>
                    Liste des utilisateurs
                  </NavLink>
                  <NavLink to='/database' activeclassname='active' className='nav-link'>
                    Base de données
                  </NavLink>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='ctn-profil'>
        <div className='cte-img'>
          <img className='picture' src={user.picture}></img>
        </div>
        <div className='cte-id'>
          <h2 className='name'>
            {user.firstname} {user.lastname}
          </h2>
          <h2 className='main-perm'>{perms.main_perm[user.main_perm]}</h2>
        </div>
        <div className='cte-action'>
          <IconButton className='btn-action' aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={actionHandleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu id='profil-actions' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={actionHandleClose}>
            <MenuItem className='disconnect' onClick={handleDisconnect}>
              <ListItemIcon className='disconnect-icon'>
                <ExitToAppRoundedIcon fontSize='small' />
              </ListItemIcon>
              Se déconnecter
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default AdminMenu;
