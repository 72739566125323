import React from 'react';
import ReactDOM from 'react-dom';
import Routing from './Routing';
import AuthContextProvider from './Context/Context';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <AuthContextProvider>
    <SnackbarProvider maxSnack={3}>
      <Routing />
    </SnackbarProvider>
  </AuthContextProvider>,
  document.getElementById('root')
);
