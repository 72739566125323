import React, { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../Context/Context';
import logo from '../../Assets/AD_Logo_Primary.svg';
import { useSnackbar } from 'notistack';
import './adminLogin.scss';

const AdminLogin = () => {
  const { setAuth } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, register } = useForm();

  const onSubmit = handleSubmit((data) => {
    var email = data.email; // "test1@example.com"
    var password = data.password; // "zzzzzz"

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        enqueueSnackbar('Bienvenue ' + email, {
          variant: 'success',
        });
        routing();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  });

  const history = useHistory();

  const routing = () => {
    // setAuth(true);
    history.push('/contracts');
  };

  return (
    <div className='admin-login'>
      <div className='ctn-form'>
        <img className='logo' src={logo} />
        <div className='header'>
          <h1 className='title'>Bienvenue sur votre application relation entreprise</h1>
          <h2 className='subtitle'>Veuillez renseigner votre email et mot de passe transmis par l'administrateur</h2>
        </div>
        <form className='cte-form' onSubmit={handleSubmit(onSubmit)}>
          <TextField {...register('email')} name='email' label='Email' variant='outlined' />
          <TextField {...register('password')} name='password' type='password' label='Mot de passe' variant='outlined' />
          <Button variant='contained' color='primary' type='submit'>
            Se connecter
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
